import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import DashboardLayout from './components/layout/DashboardLayout';
import ProjectView from './pages/ProjectView';
import Projects from './pages/Projects';
import NewProject from './pages/NewProject';
import WorkOrders from './pages/WorkOrders';
import CreateWorkOrder from './pages/CreateWorkOrder';
import WorkOrderDetails from './pages/WorkOrderDetails';
import { Toaster } from 'react-hot-toast';
import { MapsProvider } from './contexts/MapsContext';
import Team from './pages/Team';
import Schedule from './pages/Schedule';
import Documents from './pages/Documents';

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <MapsProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/:projectId" element={<ProjectView />} />
            <Route path="projects/new" element={<NewProject />} />
            <Route path="work-orders" element={<WorkOrders />} />
            <Route path="work-orders/new" element={<CreateWorkOrder />} />
            <Route path="work-orders/:id" element={<WorkOrderDetails />} />
            <Route path="team" element={<Team />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="documents" element={<Documents />} />
          </Route>
        </Routes>
        <Toaster 
          position="top-right"
          toastOptions={{
            duration: 3000,
            style: {
              background: '#fff',
              color: '#363636',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
              borderRadius: '0.375rem',
              padding: '0.75rem 1rem',
            },
          }}
        />
      </Router>
    </MapsProvider>
  );
};

export default App;

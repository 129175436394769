import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjects } from '../features/projects/projectSlice';
import Button from '../components/common/Button';
import { PlusIcon, MapIcon } from '@heroicons/react/24/outline';
import { StatusBadge } from '../components/projects/StatusBadge';
import ProjectsMap from '../components/projects/ProjectsMap';

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects, isLoading } = useSelector((state) => state.projects);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [isMapOpen, setIsMapOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'pending', label: 'Pending' },
    { value: 'sold', label: 'Sold' },
    { value: 'pre-construction', label: 'Pre-Construction' },
    { value: 'permitting', label: 'Permitting' },
    { value: 'construction', label: 'Construction' },
    { value: 'done', label: 'Done' }
  ];

  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         project.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         project.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesStatus = selectedStatus === 'all' || project.status === selectedStatus;
    
    return matchesSearch && matchesStatus;
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Projects</h1>
        <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
          <Button
            variant="secondary"
            onClick={() => setIsMapOpen(true)}
            className="flex items-center justify-center gap-2 w-full sm:w-auto"
          >
            <MapIcon className="h-5 w-5" />
            View Map
          </Button>
          <Button
            variant="primary"
            onClick={() => navigate('/projects/new')}
            className="flex items-center justify-center gap-2 w-full sm:w-auto"
          >
            <PlusIcon className="h-5 w-5" />
            New Project
          </Button>
        </div>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        >
          {statusOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block bg-white rounded-lg shadow overflow-hidden">
        <div className="min-w-full divide-y divide-gray-200">
          <div className="bg-gray-50">
            <div className="grid grid-cols-12 gap-4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <div className="col-span-3">Project</div>
              <div className="col-span-2">Status</div>
              <div className="col-span-3">Location</div>
              <div className="col-span-2">Budget</div>
              <div className="col-span-2">Progress</div>
            </div>
          </div>
          <div className="bg-white divide-y divide-gray-200">
            {filteredProjects.map((project) => (
              <div
                key={project._id}
                className="grid grid-cols-12 gap-4 px-6 py-4 cursor-pointer hover:bg-gray-50"
                onClick={() => navigate(`/projects/${project._id}`)}
              >
                <div className="col-span-3">
                  <div className="text-sm font-medium text-gray-900">
                    {project.name}
                  </div>
                  {project.tags && project.tags.length > 0 && (
                    <div className="flex flex-wrap gap-1 mt-1">
                      {project.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-span-2">
                  <StatusBadge status={project.status} />
                </div>
                <div className="col-span-3">
                  <div className="text-sm text-gray-900">
                    {project.location.address}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="text-sm text-gray-900">
                    {project.budgetId ? formatCurrency(project.budgetId.totalAmount) : 'N/A'}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="flex items-center">
                    <div className="flex-1 h-2 bg-gray-200 rounded-full">
                      <div
                        className="h-2 bg-blue-600 rounded-full"
                        style={{
                          width: `${project.stages?.reduce((acc, stage) => 
                            acc + (stage.completed ? 1 : 0), 0) / 
                            (project.stages?.length || 1) * 100}%`
                        }}
                      />
                    </div>
                    <span className="ml-2 text-sm text-gray-600">
                      {Math.round(
                        (project.stages?.reduce((acc, stage) => 
                          acc + (stage.completed ? 1 : 0), 0) / 
                          (project.stages?.length || 1) * 100)
                      )}%
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="sm:hidden space-y-4">
        {filteredProjects.map((project) => (
          <div
            key={project._id}
            className="bg-white rounded-lg shadow p-4 cursor-pointer hover:bg-gray-50"
            onClick={() => navigate(`/projects/${project._id}`)}
          >
            <div className="flex justify-between items-start mb-3">
              <div className="flex-1">
                <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
                {project.tags && project.tags.length > 0 && (
                  <div className="flex flex-wrap gap-1 mt-1">
                    {project.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <StatusBadge status={project.status} />
            </div>

            <div className="space-y-2 text-sm">
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Location:</span>
                <span className="text-gray-900">{project.location.address}</span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-gray-500">Budget:</span>
                <span className="text-gray-900">
                  {project.budgetId ? formatCurrency(project.budgetId.totalAmount) : 'N/A'}
                </span>
              </div>

              <div className="space-y-1">
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">Progress:</span>
                  <span className="text-gray-900">
                    {Math.round(
                      (project.stages?.reduce((acc, stage) => 
                        acc + (stage.completed ? 1 : 0), 0) / 
                        (project.stages?.length || 1) * 100)
                    )}%
                  </span>
                </div>
                <div className="flex-1 h-2 bg-gray-200 rounded-full">
                  <div
                    className="h-2 bg-blue-600 rounded-full"
                    style={{
                      width: `${project.stages?.reduce((acc, stage) => 
                        acc + (stage.completed ? 1 : 0), 0) / 
                        (project.stages?.length || 1) * 100}%`
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isMapOpen && (
        <ProjectsMap
          projects={filteredProjects}
          isOpen={isMapOpen}
          onClose={() => setIsMapOpen(false)}
        />
      )}
    </div>
  );
};

export default Projects;
import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkOrder, updateWorkOrder, assignToProject, assignToUser } from '../features/workOrders/workOrderSlice';
import { getCompanyUsers } from '../features/users/userSlice';
import { fetchProjects } from '../features/projects/projectSlice';
import workOrderService from '../features/workOrders/workOrderService';
import Card from '../components/common/Card';
import Breadcrumbs from '../components/common/Breadcrumbs';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import { GoogleMap } from '@react-google-maps/api';
import Map from '../components/common/Map';
import { useMaps } from '../contexts/MapsContext';
import DocumentUpload from '../components/documents/DocumentUpload';

const baseInputClass = "block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder-gray-400 text-sm shadow-sm transition-colors focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none";
const baseSelectClass = "block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 bg-white text-sm shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none";

const Spinner = () => (
  <div className="flex justify-center items-center h-96">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>
);

const MapLoadingSpinner = () => (
  <div className="h-[300px] flex items-center justify-center bg-gray-50 rounded-lg">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

/* global google */

const WorkOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workOrder, isLoading } = useSelector((state) => state.workOrders);
  const { projects = [] } = useSelector((state) => state.projects);
  const { users = [], isLoading: isUsersLoading } = useSelector((state) => state.users);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });
  const [mapLoaded, setMapLoaded] = useState(false);
  const { isLoaded } = useMaps();
  const { workOrders = [] } = useSelector((state) => state.workOrders);
  const [activeTab, setActiveTab] = useState('overview');
  const { user } = useSelector((state) => state.auth);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getWorkOrder(id));
      dispatch(getCompanyUsers());
      dispatch(fetchProjects());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (workOrder && !editedData) {
      setEditedData({
        ...workOrder,
        client: { ...workOrder.client },
        areaOfConcern: { ...workOrder.areaOfConcern },
        assignedTo: workOrder.assignedTo ? { ...workOrder.assignedTo } : null,
        assignedToProject: workOrder.assignedToProject ? { ...workOrder.assignedToProject } : null,
      });
    }
  }, [workOrder]);

  useEffect(() => {
    if (workOrder?.address?.lat && workOrder?.address?.lng) {
      setMapCenter({
        lat: parseFloat(workOrder.address.lat),
        lng: parseFloat(workOrder.address.lng)
      });
    }
  }, [workOrder]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedData({ ...workOrder });
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setEditedData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setEditedData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleAssignToProject = async (projectId) => {
    try {
      await dispatch(assignToProject({ workOrderId: id, projectId })).unwrap();
      dispatch(getWorkOrder(id));
    } catch (error) {
      console.error('Failed to assign project:', error);
    }
  };

  const handleAssignToUser = async (userId) => {
    try {
      await dispatch(assignToUser({ workOrderId: id, userId })).unwrap();
      dispatch(getWorkOrder(id));
    } catch (error) {
      console.error('Failed to assign user:', error);
      toast.error('Failed to assign user');
    }
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        client: {
          name: editedData.client?.name,
          email: editedData.client?.email,
          phone: editedData.client?.phone,
        },
        address: {
          formattedAddress: editedData.address?.formattedAddress,
          lat: editedData.address?.lat,
          lng: editedData.address?.lng,
          placeId: editedData.address?.placeId,
        },
        status: editedData.status,
        underWarranty: editedData.underWarranty,
        color: editedData.color,
        screenType: editedData.screenType,
        inspectionRelated: editedData.inspectionRelated,
        areaOfConcern: {
          gutter: editedData.areaOfConcern?.gutter || false,
          screen: editedData.areaOfConcern?.screen || false,
          aluminum: editedData.areaOfConcern?.aluminum || false,
          panels: editedData.areaOfConcern?.panels || false,
          concrete: editedData.areaOfConcern?.concrete || false,
          door: editedData.areaOfConcern?.door || false,
          screws: editedData.areaOfConcern?.screws || false,
          other: editedData.areaOfConcern?.other || '',
        },
        picturesProvided: editedData.picturesProvided,
        additionalNotes: editedData.additionalNotes,
        workPerformed: editedData.workPerformed,
        amountDue: parseFloat(editedData.amountDue) || 0,
        cashOnDelivery: editedData.cashOnDelivery,
        assignedTo: editedData.assignedTo?._id || null,
        assignedToProject: editedData.assignedToProject?._id || null,
      };

      await dispatch(updateWorkOrder({ 
        workOrderId: id, 
        workOrderData: updatedData 
      })).unwrap();
      
      setIsEditing(false);
      await dispatch(getWorkOrder(id));
      toast.success('Work order updated successfully');
    } catch (error) {
      toast.error('Failed to update work order');
    }
  };

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    setMapLoaded(true);
  }, []);

  const getWorkOrderMarkers = () => {
    return workOrders
      .filter(wo => 
        wo._id !== id && // Exclude current work order
        wo.address?.lat && 
        wo.address?.lng
      )
      .map(wo => ({
        position: {
          lat: parseFloat(wo.address.lat),
          lng: parseFloat(wo.address.lng)
        },
        title: `${wo.client?.name} - ${wo.address?.formattedAddress}`,
        projectId: wo._id // We'll use this for navigation
      }));
  };

  const handleMarkerClick = (workOrderId) => {
    navigate(`/work-orders/${workOrderId}`);
  };

  const handleDownloadDocument = async (documentId) => {
    try {
      const response = await workOrderService.getDocumentUrl(workOrder._id, documentId, user.token);
      window.open(response.url, '_blank');
    } catch (error) {
      toast.error('Failed to download document');
      console.error('Download error:', error);
    }
  };

  const handleDeleteDocument = (documentId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      workOrderService.deleteDocument(workOrder._id, documentId, user.token)
        .then(() => {
          dispatch(getWorkOrder(workOrder._id));
          toast.success('Document deleted successfully');
        })
        .catch(error => {
          toast.error('Failed to delete document');
          console.error('Delete error:', error);
        });
    }
  };

  const renderClientInformation = () => {
    if (isEditing) {
      return (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
            <input
              type="text"
              name="client.name"
              value={editedData.client?.name || ''}
              onChange={handleChange}
              className={baseInputClass}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="email"
              name="client.email"
              value={editedData.client?.email || ''}
              onChange={handleChange}
              className={baseInputClass}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
            <input
              type="tel"
              name="client.phone"
              value={editedData.client?.phone || ''}
              onChange={handleChange}
              className={baseInputClass}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
            <input
              type="text"
              name="address.formattedAddress"
              value={editedData.address?.formattedAddress || ''}
              onChange={handleChange}
              className={baseInputClass}
              disabled
            />
          </div>
          <div className="h-[300px] rounded-lg overflow-hidden">
            {!isLoaded ? <MapLoadingSpinner /> : (
              <div style={{ height: '100%', width: '100%' }}>
                <Map
                  center={mapCenter}
                  onLoad={handleMapLoad}
                  marker={editedData.address ? {
                    position: {
                      lat: parseFloat(editedData.address.lat),
                      lng: parseFloat(editedData.address.lng)
                    },
                    title: editedData.address.formattedAddress
                  } : null}
                  markers={getWorkOrderMarkers()}
                  onMarkerClick={handleMarkerClick}
                  fitMarkers={false}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Name:</span>
            <span className="text-sm text-gray-900">{workOrder.client?.name}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Email:</span>
            <span className="text-sm text-gray-900">{workOrder.client?.email}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Phone:</span>
            <span className="text-sm text-gray-900">{workOrder.client?.phone}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Address:</span>
            <span className="text-sm text-gray-900">{workOrder.address?.formattedAddress}</span>
          </div>
        </div>
        
        <div className="h-[300px] rounded-lg overflow-hidden">
          {!isLoaded ? <MapLoadingSpinner /> : (
            <div style={{ height: '100%', width: '100%' }}>
              <Map
                center={mapCenter}
                onLoad={handleMapLoad}
                marker={workOrder.address ? {
                  position: {
                    lat: parseFloat(workOrder.address.lat),
                    lng: parseFloat(workOrder.address.lng)
                  },
                  title: workOrder.address.formattedAddress
                } : null}
                markers={getWorkOrderMarkers()}
                onMarkerClick={handleMarkerClick}
                fitMarkers={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAreasOfConcern = () => {
    if (isEditing) {
      return (
        <>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {['gutter', 'screen', 'aluminum', 'panels', 'concrete', 'door', 'screws'].map((key) => (
              <div key={key}>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name={`areaOfConcern.${key}`}
                    checked={editedData.areaOfConcern?.[key] || false}
                    onChange={handleChange}
                    className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Other Concerns</label>
            <textarea
              name="areaOfConcern.other"
              value={editedData.areaOfConcern?.other || ''}
              onChange={handleChange}
              rows={3}
              className={baseInputClass}
            />
          </div>
        </>
      );
    }

    return (
      <div className="space-y-4">
        <div className="flex flex-wrap gap-2">
          {Object.entries(workOrder.areaOfConcern || {}).map(([key, value]) => {
            if (key !== 'other' && value) {
              return (
                <span
                  key={key}
                  className="inline-flex items-center px-3 py-1.5 rounded-md bg-yellow-50 text-yellow-800 text-sm font-medium ring-1 ring-yellow-600/20"
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </span>
              );
            }
            return null;
          })}
        </div>
        
        {workOrder.areaOfConcern?.other && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900 mb-2">Additional Notes</h3>
            <div className="text-sm text-gray-600 bg-gray-50 p-4 rounded-lg border border-gray-100">
              {workOrder.areaOfConcern.other}
            </div>
          </div>
        )}
        
        {Object.values(workOrder.areaOfConcern || {}).every(v => !v || v === '') && (
          <p className="text-sm text-gray-500 italic">No areas of concern specified</p>
        )}
      </div>
    );
  };

  const renderServiceSpecifications = () => {
    if (isEditing) {
      return (
        <div className="space-y-3">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Color</label>
            <select
              name="color"
              value={editedData.color}
              onChange={handleChange}
              className={baseSelectClass}
            >
              <option value="White">White</option>
              <option value="Bronze">Bronze</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Screen Type</label>
            <select
              name="screenType"
              value={editedData.screenType}
              onChange={handleChange}
              className={baseSelectClass}
            >
              <option value="Regular 14x18">Regular 14x18</option>
              <option value="No-See-Ums 20/20">No-See-Ums 20/20</option>
              <option value="17x14 Super Screen">17x14 Super Screen</option>
              <option value="17x20 Super Screen">17x20 Super Screen</option>
            </select>
          </div>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="underWarranty"
                checked={editedData.underWarranty}
                onChange={handleChange}
                className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
              />
              <span className="ml-2 text-sm text-gray-700">Under Warranty</span>
            </label>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Color:</span>
          <span className="text-sm text-gray-900">{workOrder.color}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Screen Type:</span>
          <span className="text-sm text-gray-900">{workOrder.screenType}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Under Warranty:</span>
          <span className="text-sm text-gray-900">{workOrder.underWarranty ? 'Yes' : 'No'}</span>
        </div>
      </div>
    );
  };

  const renderWorkDetails = () => {
    if (isEditing) {
      return (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Work Performed</label>
            <textarea
              name="workPerformed"
              value={editedData.workPerformed}
              onChange={handleChange}
              rows={4}
              className={baseInputClass}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Amount Due</label>
            <input
              type="number"
              name="amountDue"
              value={editedData.amountDue}
              onChange={handleChange}
              className={baseInputClass}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Work Performed</h3>
          <p className="text-sm text-gray-900 bg-gray-50 p-3 rounded-md">
            {workOrder.workPerformed}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Amount Due:</span>
          <span className="text-sm text-gray-900">${workOrder.amountDue?.toLocaleString()}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Technician:</span>
          <span className="text-sm text-gray-900">
            {workOrder.assignedTo ? 
              `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}` : 
              'Unassigned'
            }
          </span>
        </div>
      </div>
    );
  };

  const renderHeader = () => (
    <div className="mb-8">
      <Breadcrumbs 
        items={[
          { label: 'Work Orders', link: '/work-orders' },
          { label: `Work Order #${workOrder._id}` }
        ]} 
      />
      <div className="mt-6 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Work Order Details</h1>
          <p className="text-sm text-gray-500 mt-1">Created on {new Date(workOrder.createdAt).toLocaleDateString()}</p>
        </div>
        <div className="flex gap-3">
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                className="bg-emerald-500 hover:bg-emerald-600 text-white px-4 py-2 rounded-md shadow-sm transition-all duration-200 hover:shadow"
              >
                Save Changes
              </button>
              <button
                onClick={handleCancel}
                className="bg-white hover:bg-gray-50 text-gray-700 px-4 py-2 rounded-md shadow-sm transition-all duration-200 hover:shadow border border-gray-300"
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleEdit}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm transition-all duration-200 hover:shadow"
              >
                Edit
              </button>
              <button
                onClick={() => navigate('/work-orders')}
                className="bg-white hover:bg-gray-50 text-gray-700 px-4 py-2 rounded-md shadow-sm transition-all duration-200 hover:shadow border border-gray-300"
              >
                Back
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const renderAssignments = () => {
    if (isEditing) {
      return (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To
            </label>
            <Select
              value={editedData.assignedTo ? {
                value: editedData.assignedTo._id,
                label: `${editedData.assignedTo.firstName} ${editedData.assignedTo.lastName}`
              } : null}
              onChange={(selected) => {
                if (!selected) {
                  setEditedData(prev => ({
                    ...prev,
                    assignedTo: null
                  }));
                  return;
                }

                const selectedUser = users.find(user => user.value === selected.value);

                if (selectedUser) {
                  const updatedAssignedTo = {
                    _id: selectedUser.value,
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName
                  };

                  setEditedData(prev => ({
                    ...prev,
                    assignedTo: updatedAssignedTo
                  }));
                }
              }}
              options={users.map(user => ({
                value: user.value || user._id,
                label: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                lastName: user.lastName
              }))}
              isClearable
              placeholder="Select user..."
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '42px'
                })
              }}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Project
            </label>
            <Select
              value={editedData.assignedToProject ? {
                value: editedData.assignedToProject._id,
                label: editedData.assignedToProject.name
              } : null}
              onChange={(selected) => {
                if (!selected) {
                  setEditedData(prev => ({
                    ...prev,
                    assignedToProject: null
                  }));
                  return;
                }

                const selectedProject = projects.find(project => project._id === selected.value);

                if (selectedProject) {
                  const updatedAssignedToProject = {
                    _id: selectedProject._id,
                    name: selectedProject.name
                  };

                  setEditedData(prev => ({
                    ...prev,
                    assignedToProject: updatedAssignedToProject
                  }));
                }
              }}
              options={projects.map(project => ({
                value: project._id,
                label: project.name
              }))}
              isClearable
              placeholder="Select project..."
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '42px'
                })
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Assigned To:</span>
          <span className="text-sm text-gray-900">
            {workOrder.assignedTo ? 
              `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}` : 
              'Unassigned'
            }
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-500">Project:</span>
          {workOrder.assignedToProject ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/projects/${workOrder.assignedToProject._id}`);
              }}
              className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
            >
              {workOrder.assignedToProject.name}
            </button>
          ) : (
            <span className="text-sm text-gray-900">No Project</span>
          )}
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="mb-6 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {['overview', 'client-details', 'documents'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                ${activeTab === tab
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab === 'client-details' ? 'Client Details' : 
               tab === 'documents' ? 'Documents' :
               tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>
    );
  };

  const renderMainContent = () => {
    if (activeTab === 'overview') {
      return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Left Column - Main Info */}
          <div className="lg:col-span-2 space-y-4">
            {/* Status and Assignment Row */}
            <div className="grid grid-cols-2 gap-4">
              {/* Status Card */}
              <Card className="bg-white">
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-lg font-semibold text-gray-900">Status</h2>
                  <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                    workOrder.status === 'completed' ? 'bg-green-100 text-green-800' :
                    workOrder.status === 'in-progress' ? 'bg-blue-100 text-blue-800' :
                    workOrder.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {workOrder.status?.charAt(0).toUpperCase() + workOrder.status?.slice(1) || 'Pending'}
                  </div>
                </div>
                {isEditing && (
                  <select
                    name="status"
                    value={editedData.status}
                    onChange={handleChange}
                    className={baseSelectClass}
                  >
                    <option value="pending">Pending</option>
                    <option value="in-progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                )}
              </Card>

              {/* Assignment Card */}
              <Card className="bg-white">
                <h2 className="text-lg font-semibold text-gray-900 mb-2">Assignment</h2>
                {renderAssignments()}
              </Card>
            </div>

            {/* Work Details Card */}
            <Card className="bg-white">
              <div className="border-b pb-2 mb-3">
                <h2 className="text-lg font-semibold text-gray-900">Work Details</h2>
              </div>
              {renderWorkDetails()}
            </Card>
          </div>

          {/* Right Column - Additional Info */}
          <div className="space-y-4">
            {/* Service Specifications Card */}
            <Card className="bg-white">
              <div className="border-b pb-2 mb-3">
                <h2 className="text-lg font-semibold text-gray-900">Service Specifications</h2>
              </div>
              {renderServiceSpecifications()}
            </Card>

            {/* Areas of Concern Card */}
            <Card className="bg-white">
              <div className="border-b pb-2 mb-3">
                <h2 className="text-lg font-semibold text-gray-900">Areas of Concern</h2>
              </div>
              {renderAreasOfConcern()}
            </Card>
          </div>
        </div>
      );
    } else if (activeTab === 'client-details') {
      return (
        <div className="grid grid-cols-2 gap-6">
          {/* Left Column - Client Information Card */}
          <Card className="bg-white">
            <div className="border-b pb-2 mb-3">
              <h2 className="text-lg font-semibold text-gray-900">Client Information</h2>
            </div>
            <div className="space-y-4">
              {isEditing ? (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      type="text"
                      name="client.name"
                      value={editedData.client?.name || ''}
                      onChange={handleChange}
                      className={baseInputClass}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                    <input
                      type="email"
                      name="client.email"
                      value={editedData.client?.email || ''}
                      onChange={handleChange}
                      className={baseInputClass}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                    <input
                      type="tel"
                      name="client.phone"
                      value={editedData.client?.phone || ''}
                      onChange={handleChange}
                      className={baseInputClass}
                    />
                  </div>
                </>
              ) : (
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-500">Name:</span>
                    <span className="text-sm text-gray-900">{workOrder.client?.name}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-500">Email:</span>
                    <span className="text-sm text-gray-900">{workOrder.client?.email}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-500">Phone:</span>
                    <span className="text-sm text-gray-900">{workOrder.client?.phone}</span>
                  </div>
                </div>
              )}
            </div>
          </Card>

          {/* Right Column - Location Card */}
          <Card className="bg-white">
            <div className="border-b pb-2 mb-3">
              <h2 className="text-lg font-semibold text-gray-900">Location</h2>
            </div>
            <div className="space-y-4">
              {isEditing ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                  <input
                    type="text"
                    name="address.formattedAddress"
                    value={editedData.address?.formattedAddress || ''}
                    onChange={handleChange}
                    className={baseInputClass}
                    disabled
                  />
                </div>
              ) : (
                <div className="flex items-center justify-between mb-4">
                  <span className="text-sm font-medium text-gray-500">Address:</span>
                  <span className="text-sm text-gray-900">{workOrder.address?.formattedAddress}</span>
                </div>
              )}
              
              <div className="h-[400px] rounded-lg overflow-hidden">
                {!isLoaded ? <MapLoadingSpinner /> : (
                  <div style={{ height: '100%', width: '100%' }}>
                    <Map
                      center={mapCenter}
                      onLoad={handleMapLoad}
                      marker={workOrder.address ? {
                        position: {
                          lat: parseFloat(workOrder.address.lat),
                          lng: parseFloat(workOrder.address.lng)
                        },
                        title: workOrder.address.formattedAddress
                      } : null}
                      markers={getWorkOrderMarkers()}
                      onMarkerClick={handleMarkerClick}
                      fitMarkers={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      );
    } else if (activeTab === 'documents') {
      return (
        <Card className="bg-white">
          <div className="border-b pb-2 mb-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-900">Documents</h2>
              <button
                onClick={() => setShowUploadDialog(true)}
                className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-3 py-1.5 text-sm rounded-md transition-colors"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                </svg>
                Upload Document
              </button>
            </div>
          </div>

          {/* Documents List */}
          <div className="space-y-4">
            {workOrder.documents && workOrder.documents.length > 0 ? (
              <div className="divide-y divide-gray-200">
                {workOrder.documents.map((document) => (
                  <div key={document._id} className="py-4 flex justify-between items-center">
                    <div className="flex items-center space-x-4">
                      {/* Document Thumbnail */}
                      <div className="w-12 h-12 flex-shrink-0">
                        {document.contentType?.includes('image/') ? (
                          <img
                            src={document.url}
                            alt={document.name}
                            className="w-12 h-12 object-cover rounded-lg border border-gray-200"
                            onError={(e) => {
                              console.log('Image failed to load:', document.url);
                              e.target.onerror = null;
                              e.target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiPjxwYXRoIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VMaW5lam9pbj0icm91bmQiIHN0cm9rZVdpZHRoPSIyIiBkPSJNNyAyMWgxMGEyIDIgMCAwMDItMlY5LjQxNGExIDEgMCAwMC0uMjkzLS43MDdsLTUuNDE0LTUuNDE0QTEgMSAwIDAwMTIuNTg2IDNINyIvPjwvc3ZnPg==';
                            }}
                          />
                        ) : document.contentType?.includes('pdf') ? (
                          <div className="w-12 h-12 bg-red-50 rounded-lg border border-gray-200 flex items-center justify-center">
                            <svg className="w-6 h-6 text-red-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 18H17V16H7V18Z" fill="currentColor"/>
                              <path d="M17 14H7V12H17V14Z" fill="currentColor"/>
                              <path d="M7 10H11V8H7V10Z" fill="currentColor"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 8.73478 20.8946 8.48043 20.7071 8.29289L15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3H6ZM5 5C5 4.44772 5.44772 4 6 4H14V9C14 9.55228 14.4477 10 15 10H20V19C20 19.5523 19.5523 20 19 20H6C5.44772 20 5 19.5523 5 19V5ZM18.5858 8H16V5.41421L18.5858 8Z" fill="currentColor"/>
                            </svg>
                          </div>
                        ) : document.contentType?.includes('spreadsheet') || document.name.endsWith('.xlsx') || document.name.endsWith('.xls') ? (
                          <div className="w-12 h-12 bg-green-50 rounded-lg border border-gray-200 flex items-center justify-center">
                            <svg className="w-6 h-6 text-green-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" stroke="currentColor" strokeWidth="2"/>
                              <path d="M7 7H17V9H7V7Z" fill="currentColor"/>
                              <path d="M7 11H17V13H7V11Z" fill="currentColor"/>
                              <path d="M7 15H17V17H7V15Z" fill="currentColor"/>
                            </svg>
                          </div>
                        ) : document.contentType?.includes('word') || document.contentType?.includes('document') || document.name.endsWith('.doc') || document.name.endsWith('.docx') ? (
                          <div className="w-12 h-12 bg-blue-50 rounded-lg border border-gray-200 flex items-center justify-center">
                            <svg className="w-6 h-6 text-blue-600" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4 4C4 2.89543 4.89543 2 6 2H14L20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4Z" stroke="currentColor" strokeWidth="2"/>
                              <path d="M14 2V8H20" stroke="currentColor" strokeWidth="2"/>
                              <path d="M8 13H16V15H8V13Z" fill="currentColor"/>
                              <path d="M8 17H16V19H8V17Z" fill="currentColor"/>
                            </svg>
                          </div>
                        ) : (
                          <div className="w-12 h-12 bg-gray-50 rounded-lg border border-gray-200 flex items-center justify-center">
                            <svg className="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      
                      {/* Document Info */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-900">{document.name}</h4>
                        {document.description && (
                          <p className="text-sm text-gray-500">{document.description}</p>
                        )}
                        <div className="flex items-center gap-2 mt-1">
                          <p className="text-xs text-gray-400">
                            Uploaded {new Date(document.uploadDate).toLocaleDateString()}
                          </p>
                          <span className="text-gray-300">•</span>
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                            {document.uploadedBy?.firstName} {document.uploadedBy?.lastName}
                          </span>
                          <span className="text-gray-300">•</span>
                          <p className="text-xs text-gray-400">
                            {(document.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Actions */}
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => {
                          handleDownloadDocument(document._id);
                          toast.success('Downloading document...');
                        }}
                        className="text-blue-600 hover:text-blue-800 p-1 rounded-full hover:bg-blue-50 transition-colors"
                        title="Download"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setDocumentToDelete(document);
                          setShowDeleteModal(true);
                        }}
                        className="text-red-600 hover:text-red-800 p-1 rounded-full hover:bg-red-50 transition-colors"
                        title="Delete"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-6 text-gray-500">
                No documents uploaded yet
              </div>
            )}
          </div>

          {/* Upload Dialog */}
          {showUploadDialog && (
            <DocumentUpload
              documents={workOrder.documents || []}
              onUpload={async (file, description) => {
                try {
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('description', description);
                  
                  await workOrderService.uploadDocument(workOrder._id, formData, user.token);
                  await dispatch(getWorkOrder(workOrder._id));
                  toast.success('Document uploaded successfully');
                  setShowUploadDialog(false);
                } catch (error) {
                  toast.error('Failed to upload document');
                  console.error('Upload error:', error);
                }
              }}
              onClose={() => setShowUploadDialog(false)}
            />
          )}

          {/* Delete Confirmation Modal */}
          {showDeleteModal && documentToDelete && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Delete Document</h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete this document? This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      disabled={isDeleting}
                      onClick={async () => {
                        setIsDeleting(true);
                        try {
                          await workOrderService.deleteDocument(workOrder._id, documentToDelete._id, user.token);
                          await dispatch(getWorkOrder(workOrder._id));
                          toast.success('Document deleted successfully');
                          setShowDeleteModal(false);
                          setDocumentToDelete(null);
                        } catch (error) {
                          toast.error('Failed to delete document');
                          console.error('Delete error:', error);
                        } finally {
                          setIsDeleting(false);
                        }
                      }}
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:opacity-50 disabled:cursor-not-allowed sm:ml-3 sm:w-auto"
                    >
                      {isDeleting ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Deleting...
                        </>
                      ) : (
                        'Delete'
                      )}
                    </button>
                    <button
                      type="button"
                      disabled={isDeleting}
                      onClick={() => {
                        setShowDeleteModal(false);
                        setDocumentToDelete(null);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!workOrder) {
    return <div>Work order not found</div>;
  }

  return (
    <div className="container mx-auto px-6 py-8">
      <div className="max-w-[1440px] mx-auto">
        {renderHeader()}
        {renderTabs()}
        {renderMainContent()}
      </div>
    </div>
  );
};

export default WorkOrderDetails; 
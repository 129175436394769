import { useEffect, useState, useCallback, memo } from 'react';
import { GoogleMap, useLoadScript, useGoogleMap } from '@react-google-maps/api';
import { StatusBadge } from './StatusBadge';

const libraries = ['places', 'marker'];

const MapMarkers = memo(({ projects, onSelectProject }) => {
  const googleMap = useGoogleMap();
  const [localMarkers, setLocalMarkers] = useState([]);

  useEffect(() => {
    if (!googleMap || !window.google) return;

    // Clear existing markers
    localMarkers.forEach(marker => marker.setMap(null));

    // Create new markers
    const newMarkers = projects.map(project => {
      if (project.location?.coordinates?.lat && project.location?.coordinates?.lng) {
        const position = {
          lat: Number(project.location.coordinates.lat),
          lng: Number(project.location.coordinates.lng)
        };

        const marker = new window.google.maps.Marker({
          position,
          map: googleMap,
          title: project.name,
        });

        marker.addListener('click', () => {
          onSelectProject(project);
        });

        return marker;
      }
      return null;
    }).filter(Boolean);

    setLocalMarkers(newMarkers);

    return () => {
      newMarkers.forEach(marker => marker.setMap(null));
    };
  }, [googleMap, projects, onSelectProject]);

  return null;
});

MapMarkers.displayName = 'MapMarkers';

const ProjectsMap = ({ projects, isOpen, onClose }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [center, setCenter] = useState({ lat: 25.7617, lng: -80.1918 });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleSelectProject = useCallback((project) => {
    setSelectedProject(project);
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      const validProjects = projects.filter(project => {
        return project.location?.coordinates?.lat && project.location?.coordinates?.lng;
      });

      if (validProjects.length > 0) {
        const avgLat = validProjects.reduce((sum, project) => {
          return sum + Number(project.location.coordinates.lat);
        }, 0) / validProjects.length;
        
        const avgLng = validProjects.reduce((sum, project) => {
          return sum + Number(project.location.coordinates.lng);
        }, 0) / validProjects.length;

        setCenter({ lat: avgLat, lng: avgLng });
      }
    }
  }, [projects]);

  if (loadError) {
    console.error('Error loading maps:', loadError);
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-lg font-medium text-red-600 mb-2">Error Loading Map</h3>
            <p className="text-gray-500">
              Failed to load Google Maps. Please try again later.
              {loadError.message && <span className="block mt-2 text-xs">{loadError.message}</span>}
            </p>
            <button
              onClick={onClose}
              className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Project Locations ({projects.length} projects)
                  </h3>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Close</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <div className="h-[70vh] w-full relative">
                  <GoogleMap
                    mapContainerStyle={{ height: '70vh', width: '100%' }}
                    zoom={4}
                    center={center}
                    options={{
                      streetViewControl: true,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      streetViewControlOptions: {
                        position: window.google?.maps?.ControlPosition?.RIGHT_TOP
                      }
                    }}
                  >
                    <MapMarkers 
                      projects={projects} 
                      onSelectProject={handleSelectProject}
                    />
                  </GoogleMap>
                  {selectedProject && (
                    <div className="absolute bottom-4 left-4 bg-white p-4 rounded-lg shadow-lg">
                      <h3 className="font-medium">{selectedProject.name}</h3>
                      <p className="text-sm text-gray-500">{selectedProject.location.address}</p>
                      <p className="text-sm mt-1">
                        <StatusBadge status={selectedProject.status} />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsMap; 
import axios from 'axios';
import { endpoints } from '../../config/api';

// Get company users
const getCompanyUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(endpoints.users.company, config);
  return response.data;
};

const createUser = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const response = await axios.post(endpoints.users.base, userData, config);
  return response.data;
};

const updateUser = async (userId, userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const response = await axios.put(`${endpoints.users.base}/${userId}`, userData, config);
  return response.data;
};

const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const response = await axios.delete(`${endpoints.users.base}/${userId}`, config);
  return response.data;
};

const userService = {
  getCompanyUsers,
  createUser,
  updateUser,
  deleteUser
};

export default userService; 
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { 
  HomeIcon, 
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  FolderIcon,
  UsersIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
  DocumentIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { logout } from '../../features/auth/authSlice';
import { useState, useEffect } from 'react';

const Sidebar = ({ isOpen, onToggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon },
    { name: 'Projects', href: '/projects', icon: FolderIcon },
    { name: 'Scheduling', href: '/schedule', icon: CalendarIcon },
    { name: 'Work Orders', href: '/work-orders', icon: ClipboardDocumentListIcon },
    { name: 'Team', href: '/team', icon: UsersIcon },
    { name: 'Documents', href: '/documents', icon: DocumentIcon },
  ];

  const handleToggleSidebar = () => {
    if (isMobile) {
      onToggle();
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <div className="relative">
      {/* Mobile Overlay */}
      {isMobile && isOpen && (
        <div 
          className="fixed inset-0 bg-gray-900/50 z-40"
          onClick={() => onToggle()}
        />
      )}

      <aside 
        className={`
          ${isMobile ? 'fixed top-0 left-0 h-full' : 'relative h-screen'} 
          ${isMobile ? (isOpen ? 'translate-x-0' : '-translate-x-full') : (isCollapsed ? 'w-16' : 'w-64')} 
          bg-gray-800 shadow-lg flex flex-col z-50
          transition-all duration-300 ease-in-out
        `}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <div className="flex items-center gap-2">
            <BuildingOffice2Icon className="w-7 h-7 text-white" />
            {(!isCollapsed || (isMobile && isOpen)) && (
              <h1 className="text-xl font-bold text-white">NIMAX BUILDER</h1>
            )}
          </div>
          {isMobile && isOpen && (
            <button
              onClick={() => onToggle()}
              className="text-gray-300 hover:text-white"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          )}
          {!isMobile && (
            <button
              onClick={handleToggleSidebar}
              className="absolute -right-3 top-9 bg-gray-800 rounded-full p-1 text-gray-300 hover:text-white"
              title={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            >
              {isCollapsed ? (
                <ChevronRightIcon className="w-4 h-4" />
              ) : (
                <ChevronLeftIcon className="w-4 h-4" />
              )}
            </button>
          )}
        </div>

        <nav className="flex-1 overflow-y-auto p-2">
          <ul className="space-y-2">
            {navigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  onClick={isMobile ? () => onToggle() : undefined}
                  className={({ isActive }) => `
                    flex items-center p-2 rounded
                    ${isCollapsed && !isMobile ? 'justify-center' : 'justify-start'}
                    ${isActive 
                      ? 'bg-gray-700 text-white' 
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'}
                  `}
                  title={isCollapsed && !isMobile ? item.name : ''}
                >
                  <item.icon
                    className="w-5 h-5"
                    aria-hidden="true"
                  />
                  {(!isCollapsed || (isMobile && isOpen)) && (
                    <span className="ml-3">{item.name}</span>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="p-2 border-t border-gray-700">
          <button
            onClick={() => dispatch(logout())}
            className={`flex items-center p-2 w-full text-gray-300 hover:bg-gray-700 hover:text-white rounded
              ${isCollapsed && !isMobile ? 'justify-center' : 'justify-start'}`}
            title={isCollapsed && !isMobile ? "Logout" : ""}
          >
            <ArrowRightOnRectangleIcon className="w-5 h-5" />
            {(!isCollapsed || (isMobile && isOpen)) && (
              <span className="ml-3">Logout</span>
            )}
          </button>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;

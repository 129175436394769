import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { companyService } from '../features/company/companyService';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      const docs = await companyService.getDocuments();
      setDocuments(docs);
    } catch (error) {
      toast.error('Failed to load documents');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    try {
      // Create form data
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', ''); // Add description if needed

      // Upload directly
      await companyService.uploadDocument(formData);
      
      toast.success('Document uploaded successfully');
      loadDocuments();
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Failed to upload document');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await companyService.deleteDocument(documentId);
      toast.success('Document deleted successfully');
      loadDocuments();
    } catch (error) {
      toast.error('Failed to delete document');
    }
  };

  const handleDownload = async (documentId, fileName) => {
    try {
      const { url } = await companyService.getDocumentUrl(documentId);
      
      // Create temporary link and click it
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error('Failed to download document');
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Company Documents</h1>
        <label className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md cursor-pointer transition-colors">
          <span className={isUploading ? 'opacity-0' : 'opacity-100'}>Upload Document</span>
          {isUploading && (
            <span className="absolute">Uploading...</span>
          )}
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            disabled={isUploading}
          />
        </label>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {documents.map((doc) => (
          <div key={doc._id} className="bg-white rounded-lg shadow p-4">
            <div className="flex items-start justify-between">
              <div className="flex items-center space-x-3">
                <DocumentIcon className="w-6 h-6 text-blue-500" />
                <div>
                  <h3 className="font-medium">{doc.name}</h3>
                  <p className="text-sm text-gray-500">
                    Uploaded by {doc.uploadedBy?.firstName} {doc.uploadedBy?.lastName}
                  </p>
                  <p className="text-xs text-gray-400">
                    {new Date(doc.uploadedAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleDelete(doc._id)}
                className="text-red-500 hover:text-red-700"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="mt-2">
              <button
                onClick={() => handleDownload(doc._id, doc.name)}
                className="text-blue-500 hover:text-blue-700 text-sm"
              >
                Download Document
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents; 
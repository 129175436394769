import { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const baseInputClass = "block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder-gray-400 text-sm shadow-sm transition-colors focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none";

const GoogleLocationPicker = ({ value, onChange, errors }) => {
  const [searchValue, setSearchValue] = useState(value || '');
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded && !autocompleteService) {
      const service = new window.google.maps.places.AutocompleteService();
      setAutocompleteService(service);
    }
  }, [isLoaded, autocompleteService]);

  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  // Reset selectedIndex when predictions change
  useEffect(() => {
    setSelectedIndex(-1);
  }, [predictions]);

  const handleKeyDown = (e) => {
    if (!showPredictions || predictions.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => (prev < predictions.length - 1 ? prev + 1 : prev));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => (prev > 0 ? prev - 1 : 0));
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          handlePredictionSelect(predictions[selectedIndex]);
        }
        break;
      case 'Escape':
        setShowPredictions(false);
        setSelectedIndex(-1);
        break;
      default:
        break;
    }
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setShowPredictions(true);

    if (!value.trim() || !autocompleteService) {
      setPredictions([]);
      return;
    }

    try {
      const response = await autocompleteService.getPlacePredictions({
        input: value,
        types: ['address'],
      });

      setPredictions(response.predictions || []);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setPredictions([]);
    }
  };

  const handlePredictionSelect = async (prediction) => {
    setSearchValue(prediction.description);
    setPredictions([]);
    setShowPredictions(false);
    setSelectedIndex(-1);

    try {
      const geocoder = new window.google.maps.Geocoder();
      const result = await geocoder.geocode({ placeId: prediction.place_id });

      if (result.results[0]) {
        const place = result.results[0];
        const addressComponents = place.address_components;
        const location = {
          address: place.formatted_address,
          street: getAddressComponent(addressComponents, 'route'),
          city: getAddressComponent(addressComponents, 'locality'),
          state: getAddressComponent(addressComponents, 'administrative_area_level_1'),
          country: getAddressComponent(addressComponents, 'country'),
          postalCode: getAddressComponent(addressComponents, 'postal_code'),
          coordinates: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        };

        onChange({ location });
      }
    } catch (error) {
      console.error('Error getting place details:', error);
    }
  };

  const getAddressComponent = (components, type) => {
    const component = components.find(
      comp => comp.types.includes(type)
    );
    return component ? component.long_name : '';
  };

  if (loadError) {
    return (
      <div className="text-red-600">
        Error loading Google Maps: {loadError.message}
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="animate-pulse bg-gray-200 h-10 rounded-md"></div>
    );
  }

  return (
    <div className="relative">
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowPredictions(true)}
        className={`${baseInputClass} ${errors ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : ''}`}
        placeholder="Enter location"
      />
      {errors && (
        <p className="mt-1.5 text-xs text-red-600">{errors}</p>
      )}
      
      {showPredictions && predictions.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          <ul className="max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
            {predictions.map((prediction, index) => (
              <li
                key={prediction.place_id}
                onClick={() => handlePredictionSelect(prediction)}
                className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${
                  index === selectedIndex ? 'bg-blue-50 text-blue-900' : 'hover:bg-gray-50'
                }`}
              >
                <div className="flex items-center">
                  <span className="ml-3 block truncate">
                    {prediction.description}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GoogleLocationPicker; 
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { updateProject } from '../../features/projects/projectSlice';
import Button from '../common/Button';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { UsersIcon, CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';

const ProjectDetailsModal = ({ project, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    project?.constructionInfo?.startDate ? new Date(project.constructionInfo.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    project?.constructionInfo?.endDate ? new Date(project.constructionInfo.endDate) : null
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  const getProjectAssignees = () => {
    const assignees = new Set();
    
    project.stages?.forEach(stage => {
      stage.tasks?.forEach(task => {
        task.subtasks?.forEach(subtask => {
          if (subtask.assignees && Array.isArray(subtask.assignees)) {
            subtask.assignees.forEach(assignee => {
              if (assignee && typeof assignee === 'object') {
                const name = assignee.firstName && assignee.lastName 
                  ? `${assignee.firstName} ${assignee.lastName}`
                  : assignee.email?.split('@')[0];
                
                if (name) {
                  assignees.add({
                    name,
                    role: assignee.role,
                    email: assignee.email
                  });
                }
              }
            });
          }
        });
      });
    });

    return Array.from(assignees);
  };

  const handleSave = async () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    if (endDate < startDate) {
      setError('End date cannot be before start date');
      return;
    }

    setIsUpdating(true);
    try {
      await dispatch(updateProject({
        projectId: project._id,
        updates: {
          constructionInfo: {
            ...project.constructionInfo,
            startDate,
            endDate,
            updatedBy: project.constructionInfo.updatedBy
          }
        }
      })).unwrap();
      
      toast.success('Construction dates updated successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to update construction dates');
    } finally {
      setIsUpdating(false);
    }
  };

  const getRoleColor = (role) => {
    const colors = {
      ADMIN: 'bg-purple-100 text-purple-800',
      DRAFTSMAN: 'bg-blue-100 text-blue-800',
      OFFICE: 'bg-gray-100 text-gray-800',
      PROJECT_MANAGER: 'bg-green-100 text-green-800',
      SALES: 'bg-yellow-100 text-yellow-800',
      TECHNICIAN: 'bg-indigo-100 text-indigo-800',
      INSTALLER: 'bg-orange-100 text-orange-800',
      CUSTOMER: 'bg-pink-100 text-pink-800'
    };
    return colors[role] || 'bg-gray-100 text-gray-800';
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full">
          {/* Header */}
          <div className="px-6 py-4 border-b">
            <Dialog.Title className="text-xl font-semibold text-gray-900">
              {project.name}
            </Dialog.Title>
            <p className="mt-1 text-sm text-gray-500">{project.description}</p>
          </div>

          {/* Content */}
          <div className="px-6 py-4 space-y-6">
            {/* Construction Dates Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium flex items-center gap-2 text-gray-900">
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                Construction Period
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Start Date
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setError('');
                    }}
                    className="w-full rounded-md border border-gray-300 p-2"
                    dateFormat="MMMM d, yyyy"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    End Date
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={date => {
                      setEndDate(date);
                      setError('');
                    }}
                    className="w-full rounded-md border border-gray-300 p-2"
                    dateFormat="MMMM d, yyyy"
                    minDate={startDate}
                  />
                </div>
              </div>

              {error && (
                <p className="text-sm text-red-600 mt-2">{error}</p>
              )}
            </div>

            {/* Location Section */}
            <div className="space-y-2">
              <h3 className="text-lg font-medium flex items-center gap-2 text-gray-900">
                <MapPinIcon className="h-5 w-5 text-gray-500" />
                Location
              </h3>
              <p className="text-sm text-gray-600">
                {project.location?.address}
                {project.location?.city && `, ${project.location.city}`}
                {project.location?.state && `, ${project.location.state}`}
                {project.location?.zipCode && ` ${project.location.zipCode}`}
              </p>
            </div>

            {/* Assignees Section */}
            <div className="space-y-3">
              <h3 className="text-lg font-medium flex items-center gap-2 text-gray-900">
                <UsersIcon className="h-5 w-5 text-gray-500" />
                Team Members
              </h3>
              <div className="grid grid-cols-2 gap-3">
                {getProjectAssignees().map((assignee, index) => (
                  <div 
                    key={index}
                    className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                  >
                    <div>
                      <p className="font-medium text-gray-900">{assignee.name}</p>
                      <p className="text-sm text-gray-500">{assignee.email}</p>
                    </div>
                    <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${getRoleColor(assignee.role)}`}>
                      {assignee.role?.toLowerCase().replace('_', ' ')}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 bg-gray-50 rounded-b-lg border-t flex justify-end space-x-3">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              onClick={handleSave}
              disabled={isUpdating}
            >
              {isUpdating ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ProjectDetailsModal; 
const Card = ({ 
  title, 
  children, 
  actions,
  className = '' 
}) => {
  return (
    <div className={`bg-white shadow rounded-lg ${className}`}>
      {title && (
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
        </div>
      )}
      <div className="px-4 py-5 sm:p-6">
        {children}
      </div>
      {actions && (
        <div className="px-4 py-4 sm:px-6 border-t border-gray-200 bg-gray-50">
          {actions}
        </div>
      )}
    </div>
  );
};

export default Card;

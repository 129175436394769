import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ProjectDetailsModal from '../components/Calendar/ProjectDetailsModal';
import { fetchProjects } from '../features/projects/projectSlice';
import { startOfWeek, endOfWeek, addWeeks, subWeeks } from 'date-fns';

const Schedule = () => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    projectId: null,
    search: '',
  });
  const [view, setView] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const { projects, isLoading } = useSelector((state) => state.projects);

  const PROJECT_COLORS = [
    { bg: '#E8F5E9', text: '#2E7D32', border: '#A5D6A7' }, // Green
    { bg: '#E3F2FD', text: '#1565C0', border: '#90CAF9' }, // Blue
    { bg: '#FFF3E0', text: '#E65100', border: '#FFCC80' }, // Orange
    { bg: '#F3E5F5', text: '#7B1FA2', border: '#CE93D8' }, // Purple
    { bg: '#E0F7FA', text: '#00838F', border: '#80DEEA' }, // Cyan
    { bg: '#FBE9E7', text: '#D84315', border: '#FFAB91' }, // Deep Orange
    { bg: '#E8EAF6', text: '#283593', border: '#9FA8DA' }, // Indigo
    { bg: '#F1F8E9', text: '#558B2F', border: '#C5E1A5' }, // Light Green
    { bg: '#FCE4EC', text: '#C2185B', border: '#F48FB1' }, // Pink
    { bg: '#EFEBE9', text: '#4E342E', border: '#BCAAA4' }, // Brown
    { bg: '#E0F2F1', text: '#004D40', border: '#80CBC4' }, // Teal
    { bg: '#F9FBE7', text: '#827717', border: '#DCE775' }, // Lime
    { bg: '#EDE7F6', text: '#4527A0', border: '#B39DDB' }, // Deep Purple
    { bg: '#FFF8E1', text: '#F9A825', border: '#FFE082' }, // Amber
    { bg: '#E1F5FE', text: '#0277BD', border: '#81D4FA' }  // Light Blue
  ];

  const hashString = (str) => {
    const seed = 31;
    let total = 0;
    
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      total = (total * seed + char) >>> 0; // Unsigned right shift to handle large numbers
    }
    
    // Use modulo with prime number for better distribution
    return total % 2147483647;
  };

  const getProjectColor = (projectId) => {
    if (!window._usedColors) window._usedColors = new Map();
    
    if (window._usedColors.has(projectId)) {
      return window._usedColors.get(projectId);
    }

    const usedColorIndices = Array.from(window._usedColors.values())
      .map(color => PROJECT_COLORS.indexOf(color));
    
    let colorIndex = hashString(projectId) % PROJECT_COLORS.length;
    while (usedColorIndices.includes(colorIndex)) {
      colorIndex = (colorIndex + 1) % PROJECT_COLORS.length;
    }

    const color = PROJECT_COLORS[colorIndex];
    window._usedColors.set(projectId, color);
    return color;
  };

  const getProjectAssignees = (project) => {
    const assignees = new Set();
    
    console.log('Processing project:', {
      name: project.name,
      stages: project.stages?.map(stage => ({
        name: stage.name,
        tasks: stage.tasks?.map(task => ({
          name: task.name,
          subtasks: task.subtasks?.map(subtask => ({
            name: subtask.name,
            assignees: subtask.assignees
          }))
        }))
      }))
    });

    project.stages?.forEach(stage => {
      stage.tasks?.forEach(task => {
        task.subtasks?.forEach(subtask => {
          if (subtask.assignees && Array.isArray(subtask.assignees)) {
            subtask.assignees.forEach(assignee => {
              console.log('Processing assignee:', assignee);
              
              if (assignee && typeof assignee === 'object') {
                const name = assignee.firstName && assignee.lastName 
                  ? `${assignee.firstName} ${assignee.lastName}`
                  : assignee.email?.split('@')[0];
                
                if (name) {
                  assignees.add(name);
                  console.log('Added assignee:', name);
                }
              }
            });
          }
        });
      });
    });

    const assigneeArray = Array.from(assignees);
    console.log('Final assignees for project:', {
      projectName: project.name,
      assignees: assigneeArray
    });
    
    return assigneeArray;
  };

  const filteredProjects = projects?.filter(project => {
    if (project.status !== 'construction' || 
        !project.constructionInfo?.startDate || 
        !project.constructionInfo?.endDate) {
      return false;
    }
    
    if (filters.projectId && project._id !== filters.projectId) return false;
    
    if (filters.search) {
      const searchTerm = filters.search.toLowerCase();
      const projectName = project.name.toLowerCase();
      const assignees = getProjectAssignees(project);
      return projectName.includes(searchTerm) || assignees.some(assignee => assignee.toLowerCase().includes(searchTerm));
    }
    return true;
  }) || [];

  const calendarEvents = filteredProjects.map(project => {
    const colors = getProjectColor(project._id);
    const assignees = getProjectAssignees(project);
    
    return {
      id: project._id,
      title: project.name,
      start: project.constructionInfo.startDate,
      end: project.constructionInfo.endDate,
      extendedProps: {
        assignees,
        project
      },
      backgroundColor: colors.bg,
      borderColor: colors.border,
      textColor: colors.text,
      classNames: ['shadow-sm', 'border']
    };
  });

  const handleEventClick = (info) => {
    setSelectedProject(info.event.extendedProps.project);
    setIsModalOpen(true);
  };

  const handlePrevious = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCurrentDate(calendarApi.getDate());
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCurrentDate(calendarApi.getDate());
    }
  };

  const calendarRef = useRef(null);

  const handleViewChange = (newView) => {
    setView(newView);
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(
        newView === 'week' ? 'timeGridWeek' : 'dayGridMonth'
      );
    }
  };

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="p-6 h-full bg-gray-50">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Construction Schedule</h1>
        <div className="flex items-center gap-4">
          <div className="relative w-[400px]">
            <input
              type="text"
              placeholder="Search by project or assignee..."
              className="w-full pl-3 pr-32 py-2 border rounded-md"
              value={filters.search}
              onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
            />
            <select
              value={filters.projectId || ''}
              onChange={(e) => setFilters(prev => ({ 
                ...prev, 
                projectId: e.target.value || null,
                search: ''
              }))}
              className="absolute right-0 top-0 h-full w-32 pl-3 pr-8 border-l bg-transparent text-sm"
            >
              <option value="">All Projects</option>
              {projects
                ?.filter(p => 
                  p.status === 'construction' && 
                  p.constructionInfo?.startDate && 
                  p.constructionInfo?.endDate
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(project => (
                  <option key={project._id} value={project._id}>
                    {project.name}
                  </option>
                ))
              }
            </select>
          </div>

          <div className="flex rounded-lg border border-gray-300">
            <button
              onClick={() => handleViewChange('month')}
              className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                view === 'month'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
              }`}
            >
              Month
            </button>
            <button
              onClick={() => handleViewChange('week')}
              className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                view === 'week'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
              }`}
            >
              Week
            </button>
          </div>

          <div className="flex items-center">
            <button
              onClick={handlePrevious}
              className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-l-lg border-l border-y"
            >
              ←
            </button>
            <button
              onClick={handleNext}
              className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-r-lg border"
            >
              →
            </button>
          </div>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow p-4">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={view === 'month' ? 'dayGridMonth' : 'timeGridWeek'}
          initialDate={currentDate}
          events={calendarEvents}
          eventClick={handleEventClick}
          headerToolbar={{
            left: '',
            center: 'title',
            right: ''
          }}
          views={{
            dayGridMonth: {
              dayMaxEvents: 4,
            },
            timeGridWeek: {
              dayMaxEvents: true,
              slotMinTime: '06:00:00',
              slotMaxTime: '20:00:00',
              expandRows: true,
              allDaySlot: true,
              slotEventOverlap: false,
              dayHeaderFormat: { weekday: 'short', day: 'numeric' },
              scrollTime: '06:00:00',
            }
          }}
          eventContent={(eventInfo) => {
            const assignees = eventInfo.event.extendedProps.assignees;
            const isWeekView = view === 'week';
            
            return (
              <div className={`
                flex items-center gap-1 p-1 w-full
                ${isWeekView ? 'min-h-[40px]' : 'min-h-[24px]'}
              `}>
                <span className={`
                  font-medium truncate flex-shrink-0
                  ${isWeekView ? 'text-sm' : 'text-xs'}
                `}>
                  {eventInfo.event.title}
                </span>
                {assignees && assignees.length > 0 && (
                  <div className="flex items-center gap-1 overflow-hidden">
                    {assignees.map((assignee, index) => (
                      <span 
                        key={index}
                        className={`
                          px-1.5 py-0.5 bg-white bg-opacity-50 rounded truncate whitespace-nowrap
                          ${isWeekView ? 'text-xs' : 'text-[10px]'}
                        `}
                        title={assignee}
                      >
                        {assignee}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            );
          }}
          eventDidMount={(info) => {
            const isWeekView = view === 'week';
            const height = isWeekView ? '40px' : '24px';
            
            info.el.style.height = height;
            info.el.style.padding = '0px';
            info.el.style.margin = isWeekView ? '4px 0' : '1px 0';
            info.el.style.overflow = 'hidden';
            
            info.el.addEventListener('mouseenter', () => {
              info.el.style.transform = 'scale(1.02)';
              info.el.style.transition = 'all 0.1s ease-in-out';
              info.el.style.zIndex = '1';
              info.el.style.height = 'auto';
            });
            
            info.el.addEventListener('mouseleave', () => {
              info.el.style.transform = 'scale(1)';
              info.el.style.zIndex = '';
              info.el.style.height = height;
            });
          }}
          height="calc(100vh - 16rem)"
          className="fc-tailwind"
          firstDay={1}
          weekends={true}
          fixedWeekCount={false}
          showNonCurrentDates={false}
        />
      </div>

      {selectedProject && (
        <ProjectDetailsModal
          project={selectedProject}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Schedule; 
import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProject, updateProject, updateBudget, updateBudgetStatus, fetchBudgetDetails } from '../features/projects/projectSlice';
import { toast } from 'react-toastify';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { 
  CurrencyDollarIcon, 
  PencilIcon, 
  CheckIcon, 
  XMarkIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  BanknotesIcon,
  UsersIcon,
  BuildingOfficeIcon,
  DocumentTextIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  HomeIcon,
  ChevronRightIcon,
  ClockIcon,
  ChartPieIcon as ChartIcon
} from '@heroicons/react/24/outline';
import DocumentUpload from '../components/documents/DocumentUpload';
import { uploadDocument, deleteDocument } from '../features/projects/projectSlice';
import projectService from '../features/projects/projectService';
import DeleteConfirmation from '../components/documents/DeleteConfirmation';
import DocumentThumbnail from '../components/documents/DocumentThumbnail';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { getWorkOrders } from '../features/workOrders/workOrderSlice';
import Map from '../components/common/Map';
import GoogleLocationPicker from '../components/location/GoogleLocationPicker';
import { useMaps } from '../contexts/MapsContext';
import EditProjectModal from '../components/projects/EditProjectModal';
import * as XLSX from 'xlsx';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend,
  Chart // Add this import
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { assignUserToSubtask } from '../features/projects/projectSlice';
import ConstructionDateModal from '../components/projects/ConstructionDateModal';
import { store } from '../app/store';  // Change from: import store from '../app/store';

ChartJS.register(ArcElement, Tooltip, Legend);

const libraries = ['places'];

// First, add this constant at the top of the file with other imports
const BUDGET_STATUS_OPTIONS = ['draft', 'submitted', 'approved', 'rejected'];

// Add status badge component
const StatusBadge = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'done':
        return 'bg-green-50 text-green-700 border border-green-200';
      case 'pending':
        return 'bg-yellow-50 text-yellow-700 border border-yellow-200';
      case 'sold':
        return 'bg-blue-50 text-blue-700 border border-blue-200';
      case 'pre-construction':
        return 'bg-purple-50 text-purple-700 border border-purple-200';
      case 'permitting':
        return 'bg-orange-50 text-orange-700 border border-orange-200';
      case 'construction':
        return 'bg-indigo-50 text-indigo-700 border border-indigo-200';
      default:
        return 'bg-gray-50 text-gray-700 border border-gray-200';
    }
  };

  if (!status) {
    return null;
  }

  return (
    <span className={`inline-flex items-center px-2.5 py-1 rounded text-sm font-medium ${getStatusColor(status)}`}>
      {status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ')}
    </span>
  );
};

// Add these components at the top of the file, after the imports
const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message, isLoading = false }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>

        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  <span>Processing...</span>
                </div>
              ) : (
                'Confirm'
              )}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add these helper functions at the top of the file
const calculateTaskDuration = (task) => {
  if (!task.subtasks || task.subtasks.length === 0) return 0;
  return task.subtasks.reduce((sum, subtask) => sum + (subtask.duration || 0), 0);
};

const calculateStageDuration = (stage) => {
  if (!stage.tasks || stage.tasks.length === 0) return 0;
  return stage.tasks.reduce((sum, task) => sum + calculateTaskDuration(task), 0);
};

// Add this helper function at the top with other helper functions
const calculateTotalProjectDuration = (stages) => {
  if (!stages || !Array.isArray(stages)) return 0;
  return stages.reduce((total, stage) => total + calculateStageDuration(stage), 0);
};

// First, add this helper function near the top of the file, after other helper functions
const getRoleTagColor = (role) => {
  // For assigned users, use vibrant colors
  if (role?.startsWith('assigned-')) {
    const colors = {
      admin: 'bg-purple-100 text-purple-800 border-purple-300',
      manager: 'bg-indigo-100 text-indigo-800 border-indigo-300',
      draftsman: 'bg-cyan-100 text-cyan-800 border-cyan-300',
      installer: 'bg-emerald-100 text-emerald-800 border-emerald-300',
      salesman: 'bg-amber-100 text-amber-800 border-amber-300',
      default: 'bg-blue-100 text-blue-800 border-blue-300'
    };
    return colors[role?.toLowerCase()] || colors.default;
  }
  
  // For role tags (showing which roles can be assigned), use gray
  return 'bg-gray-50 text-gray-600 border-gray-200';
};

const ProjectView = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: { user } } = useSelector((state) => state.auth);
  const { currentProject, currentBudget, isLoading, isBudgetLoading } = useSelector((state) => state.projects);
  
  // Add these refs at the top with other hooks
  const initialFetchRef = useRef(false);
  const fetchTimeoutRef = useRef(null);
  
  // Move these state declarations to the top with other state declarations, right after the existing ones
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedStageIndex, setSelectedStageIndex] = useState(0);
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [editedBudget, setEditedBudget] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showConstructionDateModal, setShowConstructionDateModal] = useState({ 
    isOpen: false, 
    onConfirm: null, 
    onClose: null 
  });
  const [isAssigning, setIsAssigning] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [assignmentConfirmation, setAssignmentConfirmation] = useState({
    isOpen: false,
    stageIndex: null,
    taskIndex: null,
    subtaskIndex: null
  });
  const [completionConfirmation, setCompletionConfirmation] = useState({
    isOpen: false,
    stageName: null,
    taskName: null,
    subtaskIndex: null
  });
  const [bulkAssignmentConfirmation, setBulkAssignmentConfirmation] = useState({
    isOpen: false,
    stageIndex: null
  });
  const [localStages, setLocalStages] = useState(null);
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const { workOrders = [] } = useSelector((state) => state.workOrders);
  
  const formatCurrency = (value) => {
    const numValue = parseFloat(value) || 0;
    return numValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const calculateTotalBudget = (budget) => {
    if (!budget) return 0;
    
    const budgetFields = [
      'materialCosts',
      'officeOverhead',
      'companyProfit',
      'permitCosts',
      'draftsmanPay',
      'installerPay',
      'salesmenPay'
    ];
    
    return budgetFields.reduce((total, field) => {
      // Convert to number and handle NaN cases
      const value = parseFloat(budget[field]) || 0;
      return total + value;
    }, 0);
  };

  const fetchProjectData = useCallback(async () => {
    if (!projectId || initialFetchRef.current) return;

    try {
      initialFetchRef.current = true;
      
      // Clear any pending timeouts
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }

      // Fetch project data first
      const projectData = await dispatch(fetchProject(projectId)).unwrap();
      
      // If project has a budget ID, fetch budget details
      const budgetId = projectData?.budgetId?._id || projectData?.budgetId;
      if (budgetId) {
        await dispatch(fetchBudgetDetails(budgetId)).unwrap();
      }

      // Fetch work orders last
      await dispatch(getWorkOrders()).unwrap();
      
    } catch (error) {
      console.error('Error fetching project data:', error);
      toast.error('Failed to load project data');
      
      // Reset the ref after a delay if there was an error
      fetchTimeoutRef.current = setTimeout(() => {
        initialFetchRef.current = false;
      }, 5000);
    }
  }, [dispatch, projectId]);

  // Single useEffect for data fetching
  useEffect(() => {
    fetchProjectData();

    // Cleanup function
    return () => {
      initialFetchRef.current = false;
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      // Clear the cache when component unmounts
      projectService.clearCache();
    };
  }, [fetchProjectData]);

  useEffect(() => {
    if (!currentProject?.stages) return;
    
    const currentIndex = currentProject.stages.findIndex(stage => stage.isCurrentStage);
    setSelectedStageIndex(prevIndex => {
      if (prevIndex === 0 || currentIndex !== prevIndex) {
        return currentIndex !== -1 ? currentIndex : 0;
      }
      return prevIndex;
    });
  }, [currentProject?.stages]); // Simplify dependency

  const handleStageUpdate = (newStage) => {
    const currentStageIndex = currentProject.stages.findIndex(stage => stage.isCurrentStage);
    const newStageIndex = currentProject.stages.findIndex(stage => stage.name === newStage);

    if (newStageIndex !== -1 && newStageIndex !== currentStageIndex) {
      const updatedStages = currentProject.stages.map((stage, index) => ({
        ...stage,
        isCurrentStage: index === newStageIndex
      }));

      dispatch(updateProject({
        projectId,
        updates: { stages: updatedStages }
      }));
    }
  };

  useEffect(() => {
    if (currentProject?.stages) {
      
      const stagesWithPopulatedUsers = currentProject.stages.map(stage => ({
        ...stage,
        tasks: stage.tasks.map(task => ({
          ...task,
          subtasks: task.subtasks?.map(subtask => {
            return {
              ...subtask,
              assignees: subtask.assignees || []
            };
          })
        }))
      }));
      
      setLocalStages(stagesWithPopulatedUsers);
    }
  }, [currentProject?.stages]);

  // Update the handleChecklistUpdate function
  const handleChecklistUpdate = async (stageName, taskKey, subtaskIndex) => {
    setCompletionConfirmation({
      isOpen: true,
      stageName,
      taskName: taskKey,
      subtaskIndex
    });
  };

  const formatStageName = (stage) => {
    if (!stage) return '';
    return stage
      .split('_')
      .map(word => word.charAt(0) + word.toLowerCase().slice(1))
      .join(' ');
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleStageSelect = async (index) => {
    try {
      // First update the local state for immediate feedback
      setSelectedStageIndex(index);
      
      // Create updated stages array with new current stage
      const updatedStages = currentProject.stages.map((stage, i) => ({
        ...stage,
        isCurrentStage: i === index
      }));

      // Update local Redux state immediately
      dispatch({
        type: 'projects/updateLocalProject',
        payload: {
          ...currentProject,
          stages: updatedStages
        }
      });

      // Update the project in the backend
      await dispatch(updateProject({
        projectId,
        updates: { stages: updatedStages }
      })).unwrap();

    } catch (error) {
      console.error('Error updating current stage:', error);
      toast.error('Failed to update project stage');
      
      // Revert the local state if the update fails
      setSelectedStageIndex(
        currentProject.stages.findIndex(stage => stage.isCurrentStage)
      );
      
      // Revert Redux state
      dispatch({
        type: 'projects/updateLocalProject',
        payload: currentProject
      });
    }
  };

  // Add this new function after the formatDate function
  const calculateOverallProgress = (project) => {
    if (!project?.stages) return 0;
    
    let totalWeight = 0;
    let completedWeight = 0;
    
    // Calculate total tasks and subtasks across all stages
    project.stages.forEach(stage => {
      // Each stage contributes to 30% of total progress
      const stageWeight = 30;
      totalWeight += stageWeight;
      if (stage.completed) {
        completedWeight += stageWeight;
      }
      
      // Tasks within each stage contribute to 70% of total progress
      if (stage.tasks && stage.tasks.length > 0) {
        stage.tasks.forEach(task => {
          if (task.subtasks && task.subtasks.length > 0) {
            // If task has subtasks, weight is distributed among subtasks
            const subtaskWeight = 70 / (project.stages.length * stage.tasks.length * task.subtasks.length);
            task.subtasks.forEach(subtask => {
              totalWeight += subtaskWeight;
              if (subtask.completed) {
                completedWeight += subtaskWeight;
              }
            });
          } else {
            // If task has no subtasks, it gets full task weight
            const taskWeight = 70 / (project.stages.length * stage.tasks.length);
            totalWeight += taskWeight;
            if (task.completed) {
              completedWeight += taskWeight;
            }
          }
        });
      }
    });
    
    // Calculate percentage
    return totalWeight > 0 ? Math.round((completedWeight / totalWeight) * 100) : 0;
  };

  // Replace the existing calculateStageProgress function with this updated version
  const calculateStageProgress = () => {
    if (!currentProject?.stages) return 0;
    
    // Use the new overall progress calculation
    return calculateOverallProgress(currentProject);
  };

  // Add this new helper function near the top of the file
  const getStageStatusColor = (stage, isCurrentStage) => {
    if (stage.completed) {
      return 'bg-green-600 border-green-600 text-white';
    }
    if (isCurrentStage) {
      return 'bg-blue-600 border-blue-600 text-white';
    }
    return 'bg-white border-gray-300 text-gray-500';
  };

  // Update the renderStageProgress function
  const renderStageProgress = () => {
    if (!currentProject?.stages) return null;

    const stages = currentProject.stages;
    const currentIndex = stages.findIndex(stage => stage.isCurrentStage);
    const progress = calculateStageProgress();

    return (
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-medium text-gray-900">Project Progress</h3>
            <span className="text-sm font-medium text-gray-600">
              {Math.round(progress)}% Complete
            </span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="h-full bg-blue-600 rounded-full transition-all duration-500"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>

        <div className="relative pt-14">
          {/* Connector line */}
          <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 -translate-y-1/2" />
          
          <div className="relative flex justify-between">
            {stages.map((stage, index) => {
              const isCurrentStage = index === currentIndex;
              const isPastStage = index < currentIndex || stage.completed;
              
              return (
                <div
                  key={stage.name}
                  className="relative flex flex-col items-center group"
                  style={{ width: `${100 / stages.length}%` }}
                >
                  {/* Stage details tooltip - Now using group-hover */}
                  <div className={`
                    absolute bottom-full mb-4 left-1/2 -translate-x-1/2
                    opacity-0 group-hover:opacity-100
                    transition-opacity duration-200
                    pointer-events-none
                    z-20
                  `}>
                    <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-3 w-48">
                      <div className="text-sm">
                        <p className="font-medium text-gray-900 mb-1">
                          {formatStageName(stage.name)}
                        </p>
                        <p className="text-gray-600 text-xs">
                          {stage.completed ? 'Completed' :
                           isCurrentStage ? 'In Progress' :
                           'Pending'}
                        </p>
                        <div className="mt-2 text-xs">
                          <div className="flex justify-between text-gray-500">
                            <span>Tasks:</span>
                            <span>
                              {stage.tasks?.filter(t => t.completed).length || 0}/
                              {stage.tasks?.length || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Stage circle and number */}
                  <button
                    onClick={() => handleStageSelect(index)}
                    className={`
                      relative z-10 flex items-center justify-center
                      w-10 h-10 rounded-full border-2 
                      transition-all duration-200 
                      ${getStageStatusColor(stage, isCurrentStage)}
                      ${index === selectedStageIndex ? 'ring-2 ring-blue-100' : ''}
                      hover:shadow-md hover:scale-105
                      focus:outline-none focus:ring-2 focus:ring-blue-100
                    `}
                  >
                    {stage.completed ? (
                      <CheckIcon className="h-5 w-5" />
                    ) : (
                      <div className="h-5 w-5" /> // Empty div for consistent sizing
                    )}
                  </button>

                  {/* Stage label - Added more bottom padding */}
                  <div className="absolute -bottom-10 left-1/2 -translate-x-1/2 w-32">
                    <span className={`
                      block text-center text-sm font-medium
                      ${isCurrentStage ? 'text-blue-600' : 
                        stage.completed ? 'text-green-600' : 
                        'text-gray-500'}
                    `}>
                      {formatStageName(stage.name)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        {/* Add bottom padding to accommodate labels */}
        <div className="h-8" />
      </div>
    );
  };

  const renderAssignee = (assignee) => {
    if (!assignee) {
      return null;
    }
    
    if (!assignee._id) {
      return null;
    }
    
    const displayName = assignee.firstName && assignee.lastName 
      ? `${assignee.firstName} ${assignee.lastName}`.trim()
      : assignee.email;
    
    if (!displayName) {
      return null;
    }
    
    return (
      <span
        className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 whitespace-nowrap"
        title={displayName}
      >
        {displayName}
      </span>
    );
  };

  const renderBudgetSection = () => {
    if (!currentBudget) return null;

    const budgetStatus = currentBudget?.status || 'pending';
    
    // Helper function to safely format numbers
    const formatCurrency = (value) => {
      return (value || 0).toLocaleString();
    };
    
    return (
      <Card>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Budget Details</h3>
            <div className="flex items-center gap-2">
              <span className={`px-2 py-1 text-sm rounded-full
                ${budgetStatus === 'approved' ? 'bg-green-100 text-green-800' :
                  budgetStatus === 'rejected' ? 'bg-red-100 text-red-800' :
                  budgetStatus === 'submitted' ? 'bg-blue-100 text-blue-800' :
                  'bg-gray-100 text-gray-800'}`}>
                {budgetStatus.charAt(0).toUpperCase() + budgetStatus.slice(1)}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <p className="text-sm text-gray-600">Material Costs</p>
              <p className="text-lg font-medium">${formatCurrency(currentBudget.materialCosts)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">Office Overhead</p>
              <p className="text-lg font-medium">${formatCurrency(currentBudget.officeOverhead)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">Company Profit</p>
              <p className="text-lg font-medium">${formatCurrency(currentBudget.companyProfit)}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">Permit Costs</p>
              <p className="text-lg font-medium">${formatCurrency(currentBudget.permitCosts)}</p>
            </div>
          </div>

          <div className="border-t pt-4">
            <h4 className="text-md font-medium mb-3">Labor Costs</h4>
            <div className="grid grid-cols-3 gap-4">
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Draftsman Pay</p>
                <p className="text-md font-medium">${formatCurrency(currentBudget.draftsmanPay)}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Installer Pay</p>
                <p className="text-md font-medium">${formatCurrency(currentBudget.installerPay)}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Sales Commission</p>
                <p className="text-md font-medium">${formatCurrency(currentBudget.salesmenPay)}</p>
              </div>
            </div>
          </div>

          <div className="mt-4 pt-4 border-t">
            <div className="flex justify-between items-center">
              <span className="text-lg font-medium">Total Budget:</span>
              <span className="text-2xl font-bold text-blue-600">
                ${formatCurrency(currentBudget.totalAmount)}
              </span>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const handleBudgetStatusUpdate = async (newStatus) => {
    try {
      if (!currentBudget?._id) {
        toast.error('No budget found');
        return;
      }

      await dispatch(updateBudgetStatus({
        budgetId: currentBudget._id,
        status: newStatus
      })).unwrap();
      
      // Refresh both project and budget data
      await Promise.all([
        dispatch(fetchProject(projectId)),
        dispatch(fetchBudgetDetails(currentBudget._id))
      ]);
      
      toast.success(`Budget ${newStatus} successfully`);
    } catch (error) {
      console.error('Error updating budget status:', error);
      toast.error(error?.message || 'Failed to update budget status');
    }
  };

  const renderTabs = () => {
    return (
      <div className="mb-6 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {['overview', 'budget', 'documents', 'location', 'work-orders'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab === 'work-orders' ? 'Work Orders' : tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>
    );
  };

  const renderBudgetBriefing = () => {
    if (!currentBudget) return null;
    
    return (
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="text-sm text-gray-500">Total Budget</p>
          <p className="text-lg font-medium">
            ${formatCurrency(calculateTotalBudget(currentBudget))}
          </p>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="text-sm text-gray-500">Status</p>
          <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium
            ${currentBudget.status === 'approved' ? 'bg-green-100 text-green-800' :
              currentBudget.status === 'rejected' ? 'bg-red-100 text-red-800' :
              'bg-blue-100 text-blue-800'}`}>
            {currentBudget.status?.charAt(0).toUpperCase() + currentBudget.status?.slice(1) || 'Pending'}
          </span>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <Button
            variant="secondary"
            onClick={() => setActiveTab('budget')}
          >
            View Details
          </Button>
        </div>
      </div>
    );
  };

  const renderDocumentsBriefing = () => {
    const documentCount = currentProject.documents?.length || 0;
    
    return (
      <div className="bg-gray-50 p-4 rounded-lg flex justify-between items-center">
        <div>
          <h4 className="font-medium">Project Documents</h4>
          <p className="text-sm text-gray-500">{documentCount} document{documentCount !== 1 ? 's' : ''} uploaded</p>
        </div>
        <Button
          variant="secondary"
          onClick={() => setActiveTab('documents')}
        >
          Manage Documents
        </Button>
      </div>
    );
  };

  const LocationTab = ({ location }) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [editedLocation, setEditedLocation] = useState(location);
    const { isLoaded } = useMaps();
    const { projects } = useSelector((state) => state.projects);

    const handleLocationUpdate = async () => {
      try {
        await dispatch(updateProject({
          projectId,
          updates: { location: editedLocation }
        })).unwrap();
        
        toast.success('Location updated successfully');
        setIsEditing(false);
      } catch (error) {
        toast.error('Failed to update location');
      }
    };

    const getProjectMarkers = () => {
      return projects
        .filter(project => 
          project._id !== projectId &&
          project.location?.coordinates?.lat && 
          project.location?.coordinates?.lng
        )
        .map(project => ({
          position: {
            lat: project.location.coordinates.lat,
            lng: project.location.coordinates.lng
          },
          title: project.name,
          projectId: project._id
        }));
    };

    const handleMarkerClick = (projectId) => {
      navigate(`/projects/${projectId}`);
    };

    if (!isLoaded) {
      return (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    const center = location?.coordinates || {
      lat: 40.7128,
      lng: -74.0060
    };

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">Address Details</h3>
              <Button
                variant="secondary"
                onClick={() => {
                  if (isEditing) {
                    handleLocationUpdate();
                  } else {
                    setIsEditing(true);
                    setEditedLocation(location);
                  }
                }}
              >
                {isEditing ? 'Save Changes' : 'Edit Location'}
              </Button>
            </div>

            {isEditing ? (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-600 mb-2">
                    Search Location
                  </label>
                  <GoogleLocationPicker
                    value={editedLocation?.address || ''}
                    onChange={(locationData) => {
                      setEditedLocation(locationData.location);
                    }}
                    className="block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder-gray-400 text-sm shadow-sm transition-colors focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                  />
                </div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditing(false);
                    setEditedLocation(location);
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-600">Street Address</p>
                  <p className="font-medium mt-1">{location?.address || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">City</p>
                  <p className="font-medium mt-1">{location?.city || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">State</p>
                  <p className="font-medium mt-1">{location?.state || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">ZIP Code</p>
                  <p className="font-medium mt-1">{location?.zipCode || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Country</p>
                  <p className="font-medium mt-1">{location?.country || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Coordinates</p>
                  <p className="font-medium mt-1">
                    {location?.coordinates ? 
                      `${location.coordinates.lat.toFixed(6)}, ${location.coordinates.lng.toFixed(6)}` : 
                      'N/A'
                    }
                  </p>
                </div>
              </div>
            )}
          </div>
        </Card>

        <Card>
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Project Location</h3>
            <div style={{ height: '400px' }}>
              <Map
                center={isEditing ? editedLocation?.coordinates : center}
                marker={
                  isEditing 
                    ? (editedLocation?.coordinates 
                        ? {
                            position: editedLocation.coordinates,
                            title: editedLocation.address
                          } 
                        : null) 
                    : (location?.coordinates 
                        ? {
                            position: location.coordinates,
                            title: location.address
                          } 
                        : null)
                }
                markers={getProjectMarkers()}
                onMarkerClick={handleMarkerClick}
                fitMarkers={false}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  };

  // Update the grid layout in renderOverviewTab
  const renderOverviewTab = () => {
    return (
      <div className="grid grid-cols-12 gap-4"> {/* Using 12-column grid */}
        <div className="col-span-4 space-y-4"> {/* Takes up ~40% of the space (10% less than 50%) */}
          <Card>
            <div className="space-y-4">
              <div className="border-b pb-3">
                <h3 className="text-base font-semibold text-gray-900">Project Details</h3>
              </div>
              
              {/* Project Status */}
              <div className="flex items-center gap-2 mb-4">
                <span className="text-sm text-gray-600">Status:</span>
                <StatusBadge status={currentProject.status} />
              </div>

              {/* Estimated Duration */}
              <div className="flex items-center gap-2 mb-4">
                <span className="text-sm text-gray-600">Estimated Duration:</span>
                <span className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm font-medium">
                  {calculateTotalProjectDuration(currentProject.stages)} days
                </span>
              </div>

              {/* Tags Section */}
              <div className="flex flex-wrap gap-2 mb-4">
                {currentProject.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium border bg-blue-50 text-blue-700 border-blue-200"
                  >
                    {tag}
                  </span>
                ))}
              </div>

              <div className="space-y-3">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-1">Description</h4>
                  <p className="text-sm text-gray-600">{currentProject.description}</p>
                </div>
              </div>

              {/* Budget Overview Section */}
              <div className="pt-4 border-t">
                <h4 className="text-sm font-medium text-gray-700 mb-3">Budget Overview</h4>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm text-gray-600">Total Budget</span>
                    <span className="text-lg font-medium text-blue-600">
                      ${formatCurrency(calculateTotalBudget(currentBudget))}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className={`text-xs font-medium px-2 py-1 rounded-full
                      ${currentBudget?.status === 'approved' ? 'bg-green-100 text-green-800' :
                        currentBudget?.status === 'rejected' ? 'bg-red-100 text-red-800' :
                        'bg-blue-100 text-blue-800'}`}>
                      {(currentBudget?.status?.charAt(0)?.toUpperCase() + 
                        currentBudget?.status?.slice(1)) || 'Pending'}
                    </span>
                    <Button
                      variant="secondary"
                      onClick={() => setActiveTab('budget')}
                      className="text-sm"
                    >
                      View Details
                    </Button>
                  </div>
                </div>
              </div>

              {/* Documents Section */}
              <div className="pt-4 border-t">
                <h4 className="text-sm font-medium text-gray-700 mb-3">Documents</h4>
                <div className="space-y-3">
                  {currentProject.documents?.slice(0, 3).map((doc) => (
                    <div key={doc._id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center space-x-3">
                        <DocumentIcon className="h-5 w-5 text-gray-400" />
                        <div>
                          <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                          <p className="text-xs text-gray-500">{formatFileSize(doc.size)}</p>
                        </div>
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => handleDocumentView(doc._id)}
                        className="text-sm"
                      >
                        View
                      </Button>
                    </div>
                  ))}
                  <div className="flex justify-end">
                    <Button
                      variant="secondary"
                      onClick={() => setActiveTab('documents')}
                      className="text-sm"
                    >
                      View All Documents
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="col-span-8 space-y-4"> {/* Takes up ~60% of the space (10% more than 50%) */}
          <Card>
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <h3 className="text-base font-semibold text-gray-900">Stage Tasks</h3>
                <span className="text-sm px-3 py-1 bg-blue-50 text-blue-700 rounded-full">
                  {formatStageName(selectedStage.name)}
                </span>
              </div>
              {/* Add the new Assign All button here */}
              <div className="flex items-center gap-4">
                <span className="text-sm px-3 py-1 bg-gray-100 text-gray-700 rounded-full">
                  Total: {calculateStageDuration(selectedStage)} days
                </span>
                <Button
                  variant="secondary"
                  onClick={() => handleBulkAssignment(selectedStageIndex)}
                  disabled={currentProject.status === 'pending'}
                  className="flex items-center gap-2"
                >
                  <UsersIcon className="h-4 w-4" />
                  Assign All ({getAssignableSubtasksCount(selectedStage)})
                </Button>
              </div>
            </div>

            <div className="space-y-4">
              {tasks.map((task, taskIndex) => (
                <div key={taskIndex} className="space-y-2">
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-3">
                      <span className="text-gray-400 font-medium">{taskIndex + 1}.</span>
                      <span className="text-sm font-medium text-gray-700">{task.name}</span>
                    </div>
                    {/* Show task duration */}
                    <div className="flex items-center gap-2">
                      <span className="text-xs px-2 py-0.5 bg-blue-50 text-blue-700 rounded-full">
                        Duration: {calculateTaskDuration(task)} days
                      </span>
                      {task.completed ? (
                        <span className="text-green-600 text-sm">Completed</span>
                      ) : (
                        <span className="text-xs font-medium bg-red-100 text-red-800 px-2 py-1 rounded">
                          ACTION REQUIRED
                        </span>
                      )}
                    </div>
                  </div>

                  {task.subtasks && task.subtasks.length > 0 && (
                    <div className="ml-8 space-y-2">
                      {task.subtasks.map((subtask, subtaskIndex) => {
                        const canComplete = canCompleteSubtask(subtask);
                        const hasAssignees = subtask.assignees && subtask.assignees.length > 0;
                        const canAssign = (() => {
                          const subtaskRoles = Array.isArray(subtask.roles) ? subtask.roles : [];
                          const userRoleUpper = user?.role?.toUpperCase();
                          const hasMatchingRole = subtaskRoles
                            .map(role => role?.toUpperCase())
                            .includes(userRoleUpper);
                          const isNotAssigned = !subtask.assignees?.some(a => a._id === user?._id);
                          return hasMatchingRole && isNotAssigned;
                        })();

                        return (
                          <div 
                            key={subtaskIndex}
                            className="flex items-center justify-between p-2 rounded border border-gray-100 bg-gray-50"
                          >
                            <div className="flex items-center gap-3">
                              <span className="text-gray-400 text-sm">{subtaskIndex + 1}.</span>
                              <input
                                type="checkbox"
                                checked={subtask.completed}
                                onChange={() => handleChecklistUpdate(selectedStage.name, task.name, subtaskIndex)}
                                disabled={subtask.completed || !hasAssignees}
                                className={`h-4 w-4 rounded border-gray-300 
                                  ${!subtask.completed && canComplete && hasAssignees
                                    ? 'text-blue-600 focus:ring-blue-500 cursor-pointer' 
                                    : 'text-gray-300 cursor-not-allowed'
                                  }`}
                              />
                              <div className="flex flex-col gap-1">
                                <span className={`text-sm ${subtask.completed ? 'line-through text-gray-500' : 'text-gray-600'}`}>
                                  {subtask.name}
                                  <span className="ml-2 text-xs text-gray-500">
                                    ({subtask.duration} {subtask.duration === 1 ? 'day' : 'days'})
                                  </span>
                                </span>
                                {/* Add role tags */}
                                <div className="flex flex-wrap gap-1">
                                  {subtask.roles?.map((role, idx) => (
                                    <span
                                      key={idx}
                                      className="inline-flex items-center px-2 py-0.5 text-xs font-medium rounded border bg-gray-50 text-gray-600 border-gray-200"
                                    >
                                      {role}  {/* Role name will be displayed in its original case */}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center gap-2">
                              {/* Assignees */}
                              {hasAssignees && (
                                <div className="flex gap-1">
                                  {subtask.assignees.map((assignee, idx) => (
                                    <div key={idx} className="shrink-0">
                                      <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium border ${getRoleTagColor(`assigned-${assignee.role?.toLowerCase()}`)}`}>
                                        {formatUserName(assignee)}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                              
                              {/* Assign Me button */}
                              {canAssign && !subtask.completed && (
                                <div className="relative group">
                                  <Button
                                    variant="success"
                                    size="sm"
                                    onClick={() => handleSelfAssignment(selectedStageIndex, taskIndex, subtaskIndex)}
                                    disabled={currentProject.status === 'pending'}
                                    className={`flex items-center gap-1 py-1 px-2 text-xs bg-green-700 hover:bg-green-800 text-white
                                      ${currentProject.status === 'pending' 
                                        ? 'opacity-50 cursor-not-allowed bg-green-700' 
                                        : ''
                                      }`}
                                  >
                                    <UsersIcon className="h-3 w-3" />
                                    Assign Me
                                  </Button>
                                  
                                  {/* Tooltip for pending status */}
                                  {currentProject.status === 'pending' && (
                                    <div className="absolute bottom-full mb-2 right-0 w-48 hidden group-hover:block z-50">
                                      <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 shadow-lg">
                                        Can't assign because project is in Pending status
                                        <div className="absolute bottom-0 right-4 transform translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              
                              {/* No assignees message */}
                              {!hasAssignees && (
                                <span className="text-xs text-gray-500 italic">
                                  (No assignees)
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const handleBudgetFieldChange = (field, value) => {
    const numericValue = value === '' ? 0 : parseFloat(value);
    setEditedBudget(prev => {
      const updatedBudget = {
        ...prev,
        [field]: numericValue,
        totalAmount: calculateTotalBudget({
          ...prev,
          [field]: numericValue
        })
      };
      return updatedBudget;
    });
  };

  const handleBudgetSave = async () => {
    try {
      if (!currentBudget?._id) {
        toast.error('No budget found');
        return;
      }

      // Include status in the budget update
      await dispatch(updateBudget({
        budgetId: currentBudget._id,
        budgetData: {
          ...editedBudget,
          status: editedBudget.status || 'draft'
        }
      })).unwrap();
      
      // Refresh both project and budget data
      await Promise.all([
        dispatch(fetchProject(projectId)),
        dispatch(fetchBudgetDetails(currentBudget._id))
      ]);
      
      setIsEditingBudget(false);
      toast.success('Budget updated successfully');
    } catch (error) {
      console.error('Error updating budget:', error);
      toast.error(error?.message || 'Failed to update budget');
    }
  };

  const exportBudgetToExcel = () => {
    // Prepare the data
    const directCosts = [
      ['Direct Costs', '', ''], // Header row
      ['Material Costs', currentBudget.materialCosts, ((currentBudget.materialCosts / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['Office Overhead', currentBudget.officeOverhead, ((currentBudget.officeOverhead / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['Company Profit', currentBudget.companyProfit, ((currentBudget.companyProfit / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['Permit Costs', currentBudget.permitCosts, ((currentBudget.permitCosts / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['', '', ''], // Spacer row
    ];

    const laborCosts = [
      ['Labor Costs', '', ''], // Header row
      ['Draftsman Pay', currentBudget.draftsmanPay, ((currentBudget.draftsmanPay / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['Installer Pay', currentBudget.installerPay, ((currentBudget.installerPay / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['Sales Commission', currentBudget.salesmenPay, ((currentBudget.salesmenPay / calculateTotalBudget(currentBudget)) * 100).toFixed(1) + '%'],
      ['', '', ''], // Spacer row
    ];

    const total = [
      ['Total Budget', calculateTotalBudget(currentBudget), '100%']
    ];

    // Create workbook
    const wb = XLSX.utils.book_new();
    
    // Budget Details worksheet
    const budgetData = [
      [`Budget Report - ${currentProject.name}`],
      [`Status: ${currentBudget.status}`],
      [`Generated: ${new Date().toLocaleDateString()}`],
      [''], // Spacer row
      ['Category', 'Amount ($)', 'Percentage'], // Column headers
      ...directCosts,
      ...laborCosts,
      ...total
    ];

    const ws = XLSX.utils.aoa_to_sheet(budgetData);

    // Style the cells
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = 0; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) continue;

        // Add cell styling
        ws[cell_ref].s = {
          font: { sz: 11 },
          alignment: {
            horizontal: C === 0 ? 'left' : 'right',
            vertical: 'center'
          }
        };

        // Style headers
        if (R === 4) {
          ws[cell_ref].s.font = { bold: true, sz: 11 };
          ws[cell_ref].s.fill = { fgColor: { rgb: "EEF2F6" } };
        }

        // Style section headers
        if (ws[cell_ref].v === 'Direct Costs' || ws[cell_ref].v === 'Labor Costs') {
          ws[cell_ref].s.font = { bold: true, sz: 11 };
        }

        // Style total row
        if (ws[cell_ref].v === 'Total Budget') {
          ws[cell_ref].s.font = { bold: true, sz: 11 };
          ws[cell_ref].s.fill = { fgColor: { rgb: "E5F6FD" } };
        }
      }
    }

    // Set column widths
    ws['!cols'] = [
      { wch: 30 }, // Category
      { wch: 15 }, // Amount
      { wch: 15 }, // Percentage
    ];

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Budget Report');

    // Generate filename
    const fileName = `${currentProject.name.replace(/\s+/g, '_')}_Budget_${new Date().toISOString().split('T')[0]}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  const getBudgetChartData = (budget) => {
    if (!budget) return null;

    const data = {
      labels: [
        'Material Costs',
        'Office Overhead',
        'Company Profit',
        'Permit Costs',
        'Draftsman Pay',
        'Installer Pay',
        'Sales Commission'
      ],
      datasets: [{
        data: [
          budget.materialCosts || 0,
          budget.officeOverhead || 0,
          budget.companyProfit || 0,
          budget.permitCosts || 0,
          budget.draftsmanPay || 0,
          budget.installerPay || 0,
          budget.salesmenPay || 0
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.8)',   // Blue
          'rgba(255, 99, 132, 0.8)',   // Pink
          'rgba(75, 192, 192, 0.8)',   // Teal
          'rgba(255, 206, 86, 0.8)',   // Yellow
          'rgba(153, 102, 255, 0.8)',  // Purple
          'rgba(255, 159, 64, 0.8)',   // Orange
          'rgba(76, 175, 80, 0.8)'     // Green
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(76, 175, 80, 1)'
        ],
        borderWidth: 1
      }]
    };

    return data;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: $${value.toLocaleString()} (${percentage}%)`;
          }
        }
      }
    }
  };

  const renderBudgetTab = () => {
    if (isBudgetLoading) {
      return (
        <div className="flex justify-center items-center h-48">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    if (!currentBudget) {
      return (
        <Card>
          <div className="text-center py-8">
            <p className="text-gray-500">No budget information available</p>
          </div>
        </Card>
      );
    }

    return (
      <div className="space-y-6">
        {/* Grid container - remove fixed height */}
        <div className="grid grid-cols-12 gap-6">
          {/* Budget Details Card - now wider (6.5/12 columns) */}
          <div className="col-span-8">
            <Card>
              {/* Header */}
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-3">
                  <CurrencyDollarIcon className="h-6 w-6 text-blue-500" />
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900">Project Budget</h2>
                    <p className="text-sm text-gray-500">Financial breakdown</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {isEditingBudget ? (
                    <select
                      value={editedBudget.status || 'draft'}
                      onChange={(e) => setEditedBudget(prev => ({ ...prev, status: e.target.value }))}
                      className="block rounded-md border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 text-sm"
                    >
                      {BUDGET_STATUS_OPTIONS.map(status => (
                        <option key={status} value={status}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span className={`px-3 py-1.5 rounded-full text-sm font-medium border ${
                      currentBudget.status === 'approved' ? 'bg-green-50 text-green-700 border-green-200' :
                      currentBudget.status === 'rejected' ? 'bg-red-50 text-red-700 border-red-200' :
                      'bg-blue-50 text-blue-700 border-blue-200'
                    }`}>
                      {currentBudget.status?.charAt(0).toUpperCase() + currentBudget.status?.slice(1) || 'Pending'}
                    </span>
                  )}
                  <Button
                    variant="secondary"
                    onClick={exportBudgetToExcel}
                    className="flex items-center gap-2"
                    disabled={isEditingBudget}
                  >
                    <DocumentIcon className="h-4 w-4" />
                    <span>Export</span>
                  </Button>
                  {!isEditingBudget && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setIsEditingBudget(true);
                        setEditedBudget({
                          ...currentBudget,
                          status: currentBudget.status || 'draft'
                        });
                      }}
                      className="flex items-center gap-2"
                    >
                      <PencilIcon className="h-4 w-4" />
                      <span>Edit</span>
                    </Button>
                  )}
                </div>
              </div>

              {/* Table */}
              <div className="border rounded-lg">
                {/* Table Header */}
                <div className="grid grid-cols-12 bg-gray-50 border-b">
                  <div className="col-span-6 p-3 text-sm font-medium text-gray-900">Item</div>
                  <div className="col-span-3 p-3 text-sm font-medium text-gray-900 text-right">Amount</div>
                  <div className="col-span-3 p-3 text-sm font-medium text-gray-900 text-right">% of Total</div>
                </div>

                {/* Direct Costs Section */}
                <div className="bg-gray-100 p-3 border-b">
                  <h3 className="text-sm font-semibold text-gray-700">Direct Costs</h3>
                </div>
                {[
                  { label: 'Material Costs', field: 'materialCosts', icon: BanknotesIcon },
                  { label: 'Office Overhead', field: 'officeOverhead', icon: BuildingOfficeIcon },
                  { label: 'Company Profit', field: 'companyProfit', icon: CurrencyDollarIcon },
                  { label: 'Permit Costs', field: 'permitCosts', icon: DocumentTextIcon },
                ].map(({ label, field, icon: Icon }) => (
                  <div key={field} className="grid grid-cols-12 border-b">
                    <div className="col-span-6 p-3 flex items-center gap-2">
                      <Icon className="h-4 w-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{label}</span>
                    </div>
                    <div className="col-span-3 p-3">
                      {isEditingBudget ? (
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            value={editedBudget[field] || ''}
                            onChange={(e) => {
                              handleBudgetFieldChange(field, e.target.value);
                            }}
                            onBlur={(e) => {
                              const value = parseFloat(e.target.value) || 0;
                              handleBudgetFieldChange(field, value);
                            }}
                            className="block w-full rounded-md border border-gray-300 py-1.5 pl-7 pr-3 text-right text-gray-900 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 text-sm"
                            placeholder="0.00"
                            step="0.01"
                          />
                        </div>
                      ) : (
                        <div className="text-right text-sm text-gray-900">
                          ${formatCurrency(currentBudget[field])}
                        </div>
                      )}
                    </div>
                    <div className="col-span-3 p-3 text-right text-sm text-gray-500">
                      {((isEditingBudget ? editedBudget[field] : currentBudget[field]) / 
                        (isEditingBudget ? calculateTotalBudget(editedBudget) : calculateTotalBudget(currentBudget)) * 100).toFixed(1)}%
                    </div>
                  </div>
                ))}

                {/* Labor Costs Section */}
                <div className="bg-gray-100 p-3 border-b">
                  <h3 className="text-sm font-semibold text-gray-700">Labor Costs</h3>
                </div>
                {[
                  { label: 'Draftsman Pay', field: 'draftsmanPay' },
                  { label: 'Installer Pay', field: 'installerPay' },
                  { label: 'Sales Commission', field: 'salesmenPay' },
                ].map(({ label, field }) => (
                  <div key={field} className="grid grid-cols-12 border-b">
                    <div className="col-span-6 p-3 flex items-center gap-2">
                      <UsersIcon className="h-4 w-4 text-gray-400" />
                      <span className="text-sm text-gray-600">{label}</span>
                    </div>
                    <div className="col-span-3 p-3">
                      {isEditingBudget ? (
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            value={editedBudget[field] || ''}
                            onChange={(e) => {
                              handleBudgetFieldChange(field, e.target.value);
                            }}
                            onBlur={(e) => {
                              const value = parseFloat(e.target.value) || 0;
                              handleBudgetFieldChange(field, value);
                            }}
                            className="block w-full rounded-md border border-gray-300 py-1.5 pl-7 pr-3 text-right text-gray-900 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0 text-sm"
                            placeholder="0.00"
                            step="0.01"
                          />
                        </div>
                      ) : (
                        <div className="text-right text-sm text-gray-900">
                          ${formatCurrency(currentBudget[field])}
                        </div>
                      )}
                    </div>
                    <div className="col-span-3 p-3 text-right text-sm text-gray-500">
                      {((isEditingBudget ? editedBudget[field] : currentBudget[field]) / 
                        (isEditingBudget ? calculateTotalBudget(editedBudget) : calculateTotalBudget(currentBudget)) * 100).toFixed(1)}%
                    </div>
                  </div>
                ))}

                {/* Total Row */}
                <div className="grid grid-cols-12 bg-blue-50">
                  <div className="col-span-6 p-3 flex items-center gap-2">
                    <CurrencyDollarIcon className="h-5 w-5 text-blue-500" />
                    <span className="font-semibold text-blue-900">Total Budget</span>
                  </div>
                  <div className="col-span-3 p-3 text-right font-bold text-blue-900">
                    ${formatCurrency(isEditingBudget ? calculateTotalBudget(editedBudget) : calculateTotalBudget(currentBudget))}
                  </div>
                  <div className="col-span-3 p-3 text-right font-medium text-blue-900">
                    100%
                  </div>
                </div>
              </div>

              {/* Edit buttons */}
              {isEditingBudget && (
                <div className="flex justify-end gap-3 mt-4 pt-4 border-t">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setEditedBudget(currentBudget);
                      setIsEditingBudget(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleBudgetSave}
                  >
                    Save Changes
                  </Button>
                </div>
              )}
            </Card>
          </div>

          {/* Chart Card - now narrower (4/12 columns) */}
          <div className="col-span-4">
            <Card>
              <div className="flex items-center gap-3 mb-6">
                <ChartIcon className="h-6 w-6 text-blue-500" />
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">Budget Breakdown</h2>
                  <p className="text-sm text-gray-500">Visual distribution of project costs</p>
                </div>
              </div>
              
              <div className="h-[400px]"> {/* Fixed height for chart */}
                {getBudgetChartData(currentBudget) && (
                  <Pie 
                    data={getBudgetChartData(currentBudget)} 
                    options={chartOptions}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>

        {/* Rest of the content remains the same */}
        {/* Budget Status and Actions Card - Full width below */}
        {user.role === 'admin' && (
          <Card>
            <div className="border rounded-lg overflow-hidden">
              {/* Status Header */}
              <div className="bg-gray-50 p-4 border-b">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <ClipboardDocumentCheckIcon className="h-5 w-5 text-gray-500" />
                    <h3 className="text-sm font-semibold text-gray-900">Budget Status Management</h3>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                    currentBudget.status === 'approved' ? 'bg-green-100 text-green-700 border border-green-200' :
                    currentBudget.status === 'rejected' ? 'bg-red-100 text-red-700 border border-red-200' :
                    'bg-blue-100 text-blue-700 border border-blue-200'
                  }`}>
                    Current: {currentBudget.status?.charAt(0).toUpperCase() + currentBudget.status?.slice(1)}
                  </span>
                </div>
              </div>

              {/* Status Actions */}
              <div className="p-4 grid grid-cols-3 gap-4">
                <div className="space-y-2 p-4 bg-green-50 rounded-lg border border-green-100">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-green-800">Approve</span>
                    <CheckIcon className="h-5 w-5 text-green-600" />
                  </div>
                  <p className="text-xs text-green-600">Confirm and approve the current budget</p>
                  <Button
                    variant="success"
                    onClick={() => handleBudgetStatusUpdate('approved')}
                    disabled={currentBudget.status === 'approved'}
                    className="w-full mt-2"
                  >
                    Approve Budget
                  </Button>
                </div>

                <div className="space-y-2 p-4 bg-red-50 rounded-lg border border-red-100">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-red-800">Reject</span>
                    <XMarkIcon className="h-5 w-5 text-red-600" />
                  </div>
                  <p className="text-xs text-red-600">Reject the current budget proposal</p>
                  <Button
                    variant="danger"
                    onClick={() => handleBudgetStatusUpdate('rejected')}
                    disabled={currentBudget.status === 'rejected'}
                    className="w-full mt-2"
                  >
                    Reject Budget
                  </Button>
                </div>

                <div className="space-y-2 p-4 bg-blue-50 rounded-lg border border-blue-100">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-blue-800">Reset</span>
                    <ArrowPathIcon className="h-5 w-5 text-blue-600" />
                  </div>
                  <p className="text-xs text-blue-600">Reset status to pending for review</p>
                  <Button
                    variant="secondary"
                    onClick={() => handleBudgetStatusUpdate('pending')}
                    disabled={currentBudget.status === 'pending'}
                    className="w-full mt-2"
                  >
                    Reset to Pending
                  </Button>
                </div>
              </div>

              {/* Status History */}
              <div className="border-t">
                <div className="p-4">
                  <div className="flex items-center gap-2 mb-3">
                    <ClockIcon className="h-4 w-4 text-gray-400" />
                    <h4 className="text-sm font-medium text-gray-700">Status History</h4>
                  </div>
                  <div className="space-y-2">
                    {/* You would need to add status history to your backend and fetch it */}
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-500">Last updated</span>
                      <span className="text-gray-900">{new Date(currentBudget.updatedAt).toLocaleDateString()}</span>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-500">Created</span>
                      <span className="text-gray-900">{new Date(currentBudget.createdAt).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  };

  const handleDocumentUpload = async (file, description) => {
    try {
      await dispatch(uploadDocument({
        projectId,
        file,
        description
      })).unwrap();
      
      toast.success('Document uploaded successfully');
      setShowUploadModal(false);
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Failed to upload document');
    }
  };

  const handleDocumentDelete = async (document) => {
    setDocumentToDelete(document);
  };

  const confirmDelete = async () => {
    try {
      setIsDeleting(true);
      await dispatch(deleteDocument({
        projectId,
        documentId: documentToDelete._id
      })).unwrap();
      toast.success('Document deleted successfully');
    } catch (error) {
      toast.error('Failed to delete document');
    } finally {
      setIsDeleting(false);
      setDocumentToDelete(null);
    }
  };

  // Update the handleDocumentView function
  const handleDocumentView = async (documentId) => {
    try {
      if (!documentId) {
        toast.error('Invalid document ID');
        return;
      }

      // Get token directly from Redux store
      const { auth } = store.getState();
      const token = auth.user?.token;
      console.log('Token from store:', token); // Debug log
      
      if (!token) {
        toast.error('Authentication token not found');
        return;
      }

      const response = await projectService.getDocumentUrl(projectId, documentId, token);
      
      if (response?.url) {
        window.open(response.url, '_blank');
      } else {
        toast.error('Could not retrieve document URL');
      }
    } catch (error) {
      console.error('Document view error:', error);
      toast.error('Failed to view document');
    }
  };

  const formatUserName = (user) => {
    if (!user) return 'Unknown User';
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.email || 'Unknown User';
  };

  const renderDocumentsTab = () => {
    return (
      <>
        <Card>
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">Project Documents</h3>
              <Button 
                variant="primary"
                onClick={() => setShowUploadModal(true)}
              >
                Upload New Document
              </Button>
            </div>
            
            <div className="grid gap-4">
              {currentProject.documents?.map((doc) => (
                <div key={doc._id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center space-x-4">
                    <DocumentThumbnail 
                      document={{
                        ...doc,
                        projectId
                      }} 
                      className="h-12 w-12" 
                    />
                    <div className="space-y-1">
                      <p className="font-medium text-gray-900">{doc.name}</p>
                      {doc.description && (
                        <p className="text-sm text-gray-600">
                          {doc.description}
                        </p>
                      )}
                      <div className="flex items-center space-x-2">
                        <p className="text-xs text-gray-500">
                          {formatFileSize(doc.size)} • Uploaded on {formatDate(doc.uploadDate)}
                        </p>
                        {doc.uploadedBy && (
                          <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                            <UsersIcon className="h-3 w-3 mr-1" />
                            {formatUserName(doc.uploadedBy)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    {doc._id && (
                      <Button
                        variant="secondary"
                        onClick={() => handleDocumentView(doc._id)}
                        className="flex items-center space-x-1"
                      >
                        <DocumentIcon className="h-4 w-4" />
                        <span>View</span>
                      </Button>
                    )}
                    {doc._id && (
                      <Button 
                        variant="danger"
                        onClick={() => handleDocumentDelete(doc)}
                        className="flex items-center space-x-1"
                      >
                        <XMarkIcon className="h-4 w-4" />
                        <span>Delete</span>
                      </Button>
                    )}
                  </div>
                </div>
              ))}

              {(!currentProject.documents || currentProject.documents.length === 0) && (
                <div className="text-center py-8 text-gray-500">
                  No documents uploaded yet
                </div>
              )}
            </div>
          </div>
        </Card>

        {showUploadModal && (
          <DocumentUpload
            onUpload={handleDocumentUpload}
            onClose={() => setShowUploadModal(false)}
          />
        )}

        {documentToDelete && (
          <DeleteConfirmation
            documentName={documentToDelete.name}
            onConfirm={confirmDelete}
            onCancel={() => setDocumentToDelete(null)}
          />
        )}
      </>
    );
  };

  // Fix the formatFileSize function
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  if (isLoading || !currentProject) {
    return (
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const currentStage = currentProject.stages.find(stage => stage.isCurrentStage);
  const selectedStage = localStages?.[selectedStageIndex] || currentProject.stages?.[selectedStageIndex];
  const tasks = selectedStage?.tasks || [];

  // Add this function to filter work orders for this project
  const projectWorkOrders = workOrders.filter(
    wo => wo.assignedToProject?._id === projectId
  );

  // Add this function before the return statement
  const renderWorkOrdersTab = () => {
    if (projectWorkOrders.length === 0) {
      return (
        <Card>
          <div className="text-center py-8">
            <p className="text-gray-500">No work orders associated with this project.</p>
          </div>
        </Card>
      );
    }

    return (
      <Card>
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Associated Work Orders</h3>
            <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
              {projectWorkOrders.length} Work Order{projectWorkOrders.length !== 1 ? 's' : ''}
            </span>
          </div>

          <div className="divide-y divide-gray-200">
            {projectWorkOrders.map((workOrder) => (
              <div 
                key={workOrder._id} 
                className="py-4 cursor-pointer hover:bg-gray-50 px-4"
                onClick={() => navigate(`/work-orders/${workOrder._id}`)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-3">
                      <span className="text-sm font-medium text-gray-900">
                        {workOrder.client?.name}
                      </span>
                      <span className={`px-2 py-0.5 text-xs rounded-full ${
                        workOrder.status === 'completed' ? 'bg-green-100 text-green-800' :
                        workOrder.status === 'in-progress' ? 'bg-blue-100 text-blue-800' :
                        workOrder.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {workOrder.status?.charAt(0).toUpperCase() + workOrder.status?.slice(1)}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 mt-1">
                      {workOrder.address?.formattedAddress || 'No address provided'}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-gray-900">
                      ${workOrder.amountDue?.toLocaleString()}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(workOrder.dateRequested).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                {workOrder.workPerformed && (
                  <p className="mt-2 text-sm text-gray-600 line-clamp-2">
                    {workOrder.workPerformed}
                  </p>
                )}
                {workOrder.assignedTo && (
                  <div className="mt-2 flex items-center gap-2">
                    <span className="text-xs text-gray-500">Assigned to:</span>
                    <span className="text-xs font-medium text-gray-700">
                      {`${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}`}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>
    );
  };

  // Update the handleProjectUpdate function to handle both dates
  const handleProjectUpdate = async (updatedData) => {
    try {
      setIsUpdating(true);

      // If status is being updated to 'construction'
      if (updatedData.status === 'construction' && currentProject.status !== 'construction') {
        return new Promise((resolve) => {
          setShowConstructionDateModal({
            isOpen: true,
            onConfirm: async (startDate, endDate) => {
              try {
                const constructionInfo = {
                  initiatedBy: user._id,
                  updatedBy: user._id,
                  startDate: startDate,
                  endDate: endDate
                };

                const result = await dispatch(updateProject({
                  projectId,
                  updates: {
                    ...updatedData,
                    constructionInfo
                  }
                })).unwrap();

                toast.success('Project updated successfully');
                setShowEditModal(false);
                setShowConstructionDateModal({ isOpen: false });
                resolve(result);
              } catch (error) {
                toast.error('Failed to update project');
                resolve(null);
              }
            },
            onClose: () => {
              setShowConstructionDateModal({ isOpen: false });
              resolve(null);
            }
          });
        });
      }

      // Normal update for other cases
      const updatedProject = await dispatch(updateProject({
        projectId,
        updates: updatedData
      })).unwrap();
      
      toast.success('Project updated successfully');
      setShowEditModal(false);
      return updatedProject;
    } catch (error) {
      toast.error('Failed to update project');
      return null;
    } finally {
      setIsUpdating(false);
    }
  };

  // Update the handleSelfAssignment function
  const handleSelfAssignment = async (stageIndex, taskIndex, subtaskIndex) => {
    setAssignmentConfirmation({
      isOpen: true,
      stageIndex,
      taskIndex,
      subtaskIndex
    });
  };

  // Update the confirmAssignment function
  const confirmAssignment = async () => {
    setIsAssigning(true);
    const { stageIndex, taskIndex, subtaskIndex } = assignmentConfirmation;
    
    try {
      if (!user?._id) {
        toast.error('User not properly authenticated');
        return;
      }

      const resultAction = await dispatch(assignUserToSubtask({
        projectId,
        stageIndex,
        taskIndex,
        subtaskIndex,
        userId: user._id
      })).unwrap();

      setLocalStages(prevStages => {
        const newStages = [...prevStages];
        if (newStages[stageIndex]?.tasks[taskIndex]?.subtasks[subtaskIndex]) {
          const currentSubtask = newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex];
          newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex] = {
            ...currentSubtask,
            ...resultAction.subtask,
            _id: currentSubtask._id
          };
        }
        return newStages;
      });

      setSelectedStageIndex(prevIndex => prevIndex);
      toast.success('Successfully assigned to subtask');
    } catch (error) {
      console.error('Component - Assignment error:', error);
      if (error.response?.status === 403) {
        toast.error('You do not have the required role for this subtask');
      } else {
        toast.error('Failed to assign to subtask');
      }
    } finally {
      setIsAssigning(false);
      setAssignmentConfirmation({ isOpen: false, stageIndex: null, taskIndex: null, subtaskIndex: null });
    }
  };

  // Update the confirmCompletion function
  const confirmCompletion = async () => {
    setIsCompleting(true);
    const { stageName, taskName, subtaskIndex } = completionConfirmation;
    const stageIndex = currentProject.stages.findIndex(stage => stage.name === stageName);
    if (stageIndex === -1) return;

    try {
      const updatedStages = currentProject.stages.map(stage => {
        if (stage.name !== stageName) return stage;

        const updatedTasks = stage.tasks.map(task => {
          if (task.name !== taskName) return task;

          if (subtaskIndex !== undefined) {
            const updatedSubtasks = task.subtasks.map((subtask, idx) => {
              if (idx !== subtaskIndex) return subtask;

              if (!canCompleteSubtask(subtask)) {
                throw new Error('You must be assigned to this subtask with the correct role to complete it');
              }

              return {
                ...subtask,
                completed: !subtask.completed
              };
            });

            const allSubtasksCompleted = updatedSubtasks.every(subtask => subtask.completed);

            return {
              ...task,
              completed: allSubtasksCompleted,
              subtasks: updatedSubtasks
            };
          }

          return task;
        });

        const allTasksCompleted = updatedTasks.every(task => task.completed);

        return {
          ...stage,
          tasks: updatedTasks,
          completed: allTasksCompleted
        };
      });

      await dispatch(updateProject({
        projectId,
        updates: { stages: updatedStages }
      })).unwrap();

      dispatch({
        type: 'projects/updateLocalProject',
        payload: {
          ...currentProject,
          stages: updatedStages
        }
      });

      setLocalStages(updatedStages);
      setSelectedStageIndex(prevIndex => prevIndex);

      const updatedStage = updatedStages[stageIndex];
      if (updatedStage.completed && !currentProject.stages[stageIndex].completed) {
        toast.success('Stage completed!');
      }

    } catch (error) {
      if (error.message.includes('must be assigned')) {
        toast.error(error.message);
      } else {
        console.error('Error updating project:', error);
        toast.error('Failed to update task status');
      }
    } finally {
      setIsCompleting(false);
      setCompletionConfirmation({ isOpen: false, stageName: null, taskName: null, subtaskIndex: null });
    }
  };

  // Add the canCompleteSubtask function inside the component
  const canCompleteSubtask = (subtask) => {
    if (!subtask || !user) return false;
    
    const isAdmin = user.role?.toUpperCase() === 'ADMIN';
    if (isAdmin) return true;

    const hasMatchingRole = subtask.roles
      ?.map(role => role?.toUpperCase())
      ?.includes(user.role?.toUpperCase());
      
    const isAssigned = subtask.assignees
      ?.some(assignee => assignee._id === user._id || assignee === user._id);

    return hasMatchingRole && isAssigned;
  };

  // Update the renderSubtask function to include all necessary parameters
  const renderSubtask = (subtask, task, subtaskIndex) => (
    <div className="flex items-center gap-2 py-1.5">
      <div className="flex-1 flex items-center gap-2">
        <input
          type="checkbox"
          checked={subtask.completed}
          onChange={() => handleChecklistUpdate(selectedStage.name, task.name, subtaskIndex)}
          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <span className={`text-sm ${subtask.completed ? 'line-through text-gray-500' : 'text-gray-900'}`}>
          {subtask.name}
          <span className="ml-2 text-xs text-gray-500">
            ({subtask.duration} {subtask.duration === 1 ? 'day' : 'days'})
          </span>
        </span>
      </div>
      
      {/* Rest of the subtask UI (assignees, etc.) */}
    </div>
  );

  // Add this helper function after other helper functions
  const getAssignableSubtasksCount = (stage) => {
    if (!stage?.tasks || !user?.role) return 0;
    
    return stage.tasks.reduce((count, task) => {
      return count + (task.subtasks?.filter(subtask => {
        const subtaskRoles = Array.isArray(subtask.roles) ? subtask.roles : [];
        const userRoleUpper = user.role.toUpperCase();
        const hasMatchingRole = subtaskRoles
          .map(role => role?.toUpperCase())
          .includes(userRoleUpper);
        const isNotAssigned = !subtask.assignees?.some(a => a._id === user?._id);
        const isNotCompleted = !subtask.completed;
        return hasMatchingRole && isNotAssigned && isNotCompleted;
      }).length || 0);
    }, 0);
  };

  // Add this new function to handle bulk assignment
  const handleBulkAssignment = async (stageIndex) => {
    const assignableCount = getAssignableSubtasksCount(currentProject.stages[stageIndex]);
    if (assignableCount === 0) {
      toast.info('No eligible subtasks found for your role');
      return;
    }
    
    setBulkAssignmentConfirmation({
      isOpen: true,
      stageIndex
    });
  };

  // Add this new function to confirm bulk assignment
  const confirmBulkAssignment = async () => {
    setIsAssigning(true);
    const { stageIndex } = bulkAssignmentConfirmation;
    
    try {
      if (!user?._id) {
        toast.error('User not properly authenticated');
        return;
      }

      const stage = currentProject.stages[stageIndex];
      let assignedCount = 0;

      // Process each task and subtask
      for (let taskIndex = 0; taskIndex < stage.tasks.length; taskIndex++) {
        const task = stage.tasks[taskIndex];
        
        for (let subtaskIndex = 0; subtaskIndex < (task.subtasks?.length || 0); subtaskIndex++) {
          const subtask = task.subtasks[subtaskIndex];
          
          // Check if subtask is eligible for assignment
          const subtaskRoles = Array.isArray(subtask.roles) ? subtask.roles : [];
          const userRoleUpper = user.role.toUpperCase();
          const hasMatchingRole = subtaskRoles
            .map(role => role?.toUpperCase())
            .includes(userRoleUpper);
          const isNotAssigned = !subtask.assignees?.some(a => a._id === user._id);
          const isNotCompleted = !subtask.completed;

          if (hasMatchingRole && isNotAssigned && isNotCompleted) {
            try {
              await dispatch(assignUserToSubtask({
                projectId,
                stageIndex,
                taskIndex,
                subtaskIndex,
                userId: user._id
              })).unwrap();
              assignedCount++;
            } catch (error) {
              console.error('Error assigning subtask:', error);
            }
          }
        }
      }

      if (assignedCount > 0) {
        toast.success(`Successfully assigned ${assignedCount} subtask${assignedCount !== 1 ? 's' : ''}`);
      } else {
        toast.info('No subtasks were assigned');
      }

    } catch (error) {
      console.error('Bulk assignment error:', error);
      toast.error('Failed to complete bulk assignment');
    } finally {
      setIsAssigning(false);
      setBulkAssignmentConfirmation({ isOpen: false, stageIndex: null });
    }
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <div className="max-w-[1440px] mx-auto">
        <Breadcrumbs 
          items={[
            { label: 'Projects', link: '/projects' },
            { label: currentProject?.name || 'Project Details' }
          ]} 
        />
        
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4 space-y-4">
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{currentProject?.name}</h1>
                {currentProject?.status && <StatusBadge status={currentProject.status} />}
              </div>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(true)}
                className="flex items-center space-x-2"
              >
                <PencilIcon className="h-4 w-4" />
                <span>Edit Project</span>
              </Button>
            </div>

            <div className="space-y-4">
              {/* Stage Progress */}
              <div className="relative">
                {/* Connector line */}
                <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 -translate-y-1/2" />
                
                <div className="relative flex justify-between">
                  {(() => {
                    const currentIndex = currentProject.stages.findIndex(stage => stage.isCurrentStage);
                    
                    return currentProject.stages.map((stage, index) => {
                      const isCurrentStage = index === currentIndex;
                      
                      return (
                        <div
                          key={stage.name}
                          className="relative flex flex-col items-center group"
                          style={{ width: `${100 / currentProject.stages.length}%` }}
                        >
                          {/* Stage details tooltip */}
                          <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 opacity-0 
                            group-hover:opacity-100
                            transition-opacity duration-200 pointer-events-none z-20">
                            <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-2 w-40">
                              <div className="text-xs">
                                <p className="font-medium text-gray-900">
                                  {formatStageName(stage.name)}
                                </p>
                                <p className="text-gray-600 mt-1">
                                  {stage.completed ? 'Completed' : 
                                   isCurrentStage ? 'In Progress' : 
                                   'Pending'}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Stage circle */}
                          <button
                            onClick={() => handleStageSelect(index)}
                            className={`
                              relative z-10 flex items-center justify-center
                              w-10 h-10 rounded-full border-2 
                              transition-all duration-200 
                              ${getStageStatusColor(stage, isCurrentStage)}
                              ${index === selectedStageIndex ? 'ring-2 ring-blue-100' : ''}
                              hover:shadow-md hover:scale-105
                              focus:outline-none focus:ring-2 focus:ring-blue-100
                            `}
                          >
                            {stage.completed ? (
                              <CheckIcon className="h-5 w-5" />
                            ) : (
                              <div className="h-5 w-5" /> // Empty div for consistent sizing
                            )}
                          </button>

                          {/* Stage label */}
                          <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 w-24">
                            <span className={`
                              block text-center text-xs font-medium truncate
                              ${isCurrentStage ? 'text-blue-600' : 
                                stage.completed ? 'text-green-600' : 
                                'text-gray-500'}
                            `}>
                              {formatStageName(stage.name)}
                            </span>
                          </div>
                        </div>
                      );
                    });
                  })()}
                </div>
              </div>

              {/* Progress bar */}
              <div className="pt-8">
                <div className="flex justify-between items-center mb-1">
                  <span className="text-sm text-gray-600">Overall Progress</span>
                  <span className="text-sm font-medium text-gray-900">
                    {Math.round(calculateStageProgress())}%
                  </span>
                </div>
                <div className="h-1.5 bg-gray-200 rounded-full overflow-hidden">
                  <div 
                    className={`h-full rounded-full transition-all duration-500 ${
                      calculateStageProgress() === 100 ? 'bg-green-600' : 'bg-blue-600'
                    }`}
                    style={{ width: `${calculateStageProgress()}%` }}
                  />
                </div>
              </div>
            </div>
          </div>

          {renderTabs()}

          {activeTab === 'overview' && renderOverviewTab()}
          {activeTab === 'budget' && renderBudgetTab()}
          {activeTab === 'documents' && renderDocumentsTab()}
          {activeTab === 'location' && <LocationTab location={currentProject.location} />}
          {activeTab === 'work-orders' && renderWorkOrdersTab()}
        </div>
      </div>

      {showEditModal && (
        <EditProjectModal
          project={currentProject}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleProjectUpdate}
          isSubmitting={isUpdating}
        />
      )}

      {/* Assignment Confirmation Modal */}
      <ConfirmationModal
        isOpen={assignmentConfirmation.isOpen}
        onClose={() => setAssignmentConfirmation({ isOpen: false, stageIndex: null, taskIndex: null, subtaskIndex: null })}
        onConfirm={confirmAssignment}
        title="Confirm Assignment"
        message="Are you sure you want to assign this task to yourself?"
        isLoading={isAssigning}
      />

      {/* Completion Confirmation Modal */}
      <ConfirmationModal
        isOpen={completionConfirmation.isOpen}
        onClose={() => setCompletionConfirmation({ isOpen: false, stageName: null, taskName: null, subtaskIndex: null })}
        onConfirm={confirmCompletion}
        title="Confirm Completion"
        message="Are you sure you want to mark this task as completed?"
        isLoading={isCompleting}
      />

      {showConstructionDateModal.isOpen && (
        <ConstructionDateModal
          isOpen={showConstructionDateModal.isOpen}
          onClose={showConstructionDateModal.onClose}
          onConfirm={showConstructionDateModal.onConfirm}
        />
      )}

      {/* Bulk Assignment Confirmation Modal */}
      <ConfirmationModal
        isOpen={bulkAssignmentConfirmation.isOpen}
        onClose={() => setBulkAssignmentConfirmation({ isOpen: false, stageIndex: null })}
        onConfirm={confirmBulkAssignment}
        title="Confirm Bulk Assignment"
        message={`Are you sure you want to assign all eligible subtasks in this stage to yourself? This will assign ${getAssignableSubtasksCount(currentProject.stages[bulkAssignmentConfirmation.stageIndex])} subtask(s).`}
        isLoading={isAssigning}
      />
    </div>
  );
};

export default ProjectView;

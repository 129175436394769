import axios from 'axios';
import { endpoints } from '../../config/api';

const getTemplates = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(endpoints.templates.base, config);
  return response.data;
};

const createTemplate = async (templateData, token) => {
  const validateSubtasks = (stages) => {
    for (const stage of stages) {
      for (const task of stage.tasks) {
        for (const subtask of (task.subtasks || [])) {
          if (!subtask.duration || subtask.duration < 0.5) {
            throw new Error('All subtasks must have a valid duration (minimum 0.5 days)');
          }
        }
      }
    }
  };

  try {
    validateSubtasks(templateData.stages);
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    const response = await axios.post(endpoints.templates.base, templateData, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to create template');
  }
};

const updateTemplate = async (templateData, token) => {
  const validateSubtasks = (stages) => {
    for (const stage of stages) {
      for (const task of stage.tasks) {
        for (const subtask of (task.subtasks || [])) {
          if (!subtask.duration || subtask.duration < 0.5) {
            throw new Error('All subtasks must have a valid duration (minimum 0.5 days)');
          }
        }
      }
    }
  };

  if (!templateData._id) {
    throw new Error('Template ID is required');
  }

  try {
    validateSubtasks(templateData.stages);
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(
      `${endpoints.templates.base}/${templateData._id}`,
      templateData,
      config
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to update template');
  }
};

const templateService = {
  getTemplates,
  createTemplate,
  updateTemplate
};

export default templateService; 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderService from './workOrderService';

const initialState = {
  workOrders: [],
  workOrder: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Get all work orders
export const getWorkOrders = createAsyncThunk(
  'workOrders/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.getWorkOrders(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single work order
export const getWorkOrder = createAsyncThunk(
  'workOrders/get',
  async (workOrderId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.getWorkOrder(workOrderId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new work order
export const createWorkOrder = createAsyncThunk(
  'workOrders/create',
  async (workOrderData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.createWorkOrder(workOrderData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update work order
export const updateWorkOrder = createAsyncThunk(
  'workOrders/update',
  async ({ workOrderId, workOrderData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.updateWorkOrder(workOrderId, workOrderData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete work order
export const deleteWorkOrder = createAsyncThunk(
  'workOrders/delete',
  async (workOrderId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.deleteWorkOrder(workOrderId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign to project
export const assignToProject = createAsyncThunk(
  'workOrders/assignToProject',
  async ({ workOrderId, projectId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.assignToProject(workOrderId, projectId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign to user
export const assignToUser = createAsyncThunk(
  'workOrders/assignToUser',
  async ({ workOrderId, userId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await workOrderService.assignToUser(workOrderId, userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = action.payload;
      })
      .addCase(getWorkOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrder = action.payload;
      })
      .addCase(getWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders.push(action.payload);
      })
      .addCase(createWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.workOrders) {
          state.workOrders = state.workOrders.map((workOrder) =>
            workOrder._id === action.payload._id ? action.payload : workOrder
          );
        }
        if (state.workOrder && state.workOrder._id === action.payload._id) {
          state.workOrder = action.payload;
        }
      })
      .addCase(updateWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = state.workOrders.filter(
          (workOrder) => workOrder._id !== action.payload.id
        );
      })
      .addCase(deleteWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assignToProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignToProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = state.workOrders.map((workOrder) =>
          workOrder._id === action.payload._id ? action.payload : workOrder
        );
      })
      .addCase(assignToProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assignToUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = state.workOrders.map((workOrder) =>
          workOrder._id === action.payload._id ? action.payload : workOrder
        );
      })
      .addCase(assignToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = workOrderSlice.actions;
export default workOrderSlice.reducer; 
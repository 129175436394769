import { BellIcon } from '@heroicons/react/24/outline';
import UserMenu from './UserMenu';

const Navbar = () => {
  return (
    <div className="bg-white border-b border-gray-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex"></div>
          <div className="flex items-center gap-4">
            <button className="p-2 text-gray-400 hover:text-gray-500">
              <BellIcon className="h-6 w-6" />
            </button>
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import projectService from './projectService';

const initialState = {
  projects: [],
  project: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isBudgetLoading: false,
  message: '',
  currentProject: null,
  currentBudget: null,
};

// Fetch all projects
export const fetchProjects = createAsyncThunk(
  'projects/fetchAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getProjects(token);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new project
export const createProject = createAsyncThunk(
  'projects/create',
  async (projectData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.createProject(projectData, token);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add these new actions to your existing slice
export const fetchProject = createAsyncThunk(
  'projects/fetchProject',
  async (projectId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getProject(projectId, token);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProject = createAsyncThunk(
  'projects/update',
  async ({ projectId, updates }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const response = await projectService.updateProject(projectId, updates, token);
      return response;
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBudget = createAsyncThunk(
  'projects/updateBudget',
  async ({ budgetId, budgetData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.updateBudget(budgetId, budgetData, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateBudgetStatus = createAsyncThunk(
  'projects/updateBudgetStatus',
  async ({ budgetId, status }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.updateBudgetStatus(budgetId, status, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getBudgetHistory = createAsyncThunk(
  'projects/getBudgetHistory',
  async (budgetId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getBudgetHistory(budgetId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Add this new action
export const fetchBudgetDetails = createAsyncThunk(
  'projects/fetchBudgetDetails',
  async (budgetId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.getBudgetDetails(budgetId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Add these new thunks to your existing slice

export const uploadDocument = createAsyncThunk(
  'projects/uploadDocument',
  async ({ projectId, file, description }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.uploadProjectDocument(projectId, file, description, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'projects/deleteDocument',
  async ({ projectId, documentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectService.deleteProjectDocument(projectId, documentId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const assignUserToSubtask = createAsyncThunk(
  'projects/assignUserToSubtask',
  async ({ projectId, stageIndex, taskIndex, subtaskIndex, userId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const response = await projectService.assignUserToSubtask(
        projectId,
        { stageIndex, taskIndex, subtaskIndex, userId },
        token
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    updateLocalProject: (state, action) => {
      if (JSON.stringify(state.currentProject) !== JSON.stringify(action.payload)) {
        state.currentProject = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects.push(action.payload);
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.isLoading = false;
        const project = {
          ...action.payload,
          budgetId: action.payload.budgetId?._id || action.payload.budgetId || action.payload.budget?._id,
          stages: action.payload.stages.map(stage => ({
            ...stage,
            tasks: stage.tasks.map(task => ({
              ...task,
              completed: task.completed,
              subtasks: task.subtasks.map(subtask => ({
                ...subtask,
                completed: subtask.completed,
                roles: [...(subtask.roles || [])],
                assignees: [...(subtask.assignees || [])]
              }))
            }))
          }))
        };
        
        state.currentProject = project;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const updatedProject = {
          ...action.payload,
          stages: action.payload.stages.map(stage => ({
            ...stage,
            tasks: stage.tasks.map(task => {
              // First calculate if all subtasks are completed
              const allSubtasksCompleted = task.subtasks?.every(subtask => subtask.completed === true);
              
              return {
                ...task,
                // Set task completion based on subtasks
                completed: allSubtasksCompleted,
                subtasks: task.subtasks.map(subtask => ({
                  ...subtask,
                  // Ensure completed is a boolean
                  completed: Boolean(subtask.completed),
                  roles: [...(subtask.roles || [])],
                  assignees: [...(subtask.assignees || [])]
                }))
              };
            })
          }))
        };

        state.currentProject = updatedProject;
        state.projects = state.projects.map(project => 
          project._id === updatedProject._id ? updatedProject : project
        );
      })
      .addCase(updateBudget.fulfilled, (state, action) => {
        if (state.currentProject?.budgetId?._id === action.payload._id) {
          state.currentProject.budgetId = action.payload;
        }
      })
      .addCase(updateBudgetStatus.fulfilled, (state, action) => {
        if (state.currentProject?.budgetId?._id === action.payload._id) {
          state.currentProject.budgetId = action.payload;
        }
      })
      .addCase(getBudgetHistory.fulfilled, (state, action) => {
        state.budgetHistory = action.payload;
      })
      .addCase(fetchBudgetDetails.pending, (state) => {
        state.isBudgetLoading = true;
        state.error = null;
      })
      .addCase(fetchBudgetDetails.fulfilled, (state, action) => {
        state.isBudgetLoading = false;
        
        if (!state.currentBudget || JSON.stringify(state.currentBudget) !== JSON.stringify(action.payload)) {
          state.currentBudget = action.payload;
        }
        console.log('Budget details fetched:', action.payload);
      })
      .addCase(fetchBudgetDetails.rejected, (state, action) => {
        state.isBudgetLoading = false;
        state.error = action.payload;
        console.error('Budget fetch error:', action.payload);
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        if (state.currentProject) {
          if (!state.currentProject.documents) {
            state.currentProject.documents = [];
          }
          state.currentProject.documents.unshift(action.payload);
        }
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        if (state.currentProject) {
          state.currentProject.documents = state.currentProject.documents.filter(
            doc => doc._id !== action.meta.arg.documentId
          );
        }
      })
      .addCase(assignUserToSubtask.fulfilled, (state, action) => {
        if (state.currentProject) {
          const { stageIndex, taskIndex, subtaskIndex } = action.meta.arg;
          
          // Create a deep copy of the current project
          const updatedProject = {
            ...state.currentProject,
            stages: state.currentProject.stages.map((stage, sIndex) => {
              if (sIndex !== stageIndex) return stage;
              
              return {
                ...stage,
                tasks: stage.tasks.map((task, tIndex) => {
                  if (tIndex !== taskIndex) return task;
                  
                  return {
                    ...task,
                    subtasks: task.subtasks.map((subtask, stIndex) => {
                      if (stIndex !== subtaskIndex) return subtask;
                      
                      return {
                        ...subtask,
                        ...action.payload.subtask,
                        _id: subtask._id
                      };
                    })
                  };
                })
              };
            })
          };
          
          // Update both currentProject and projects array
          state.currentProject = updatedProject;
          state.projects = state.projects.map(project => 
            project._id === updatedProject._id ? updatedProject : project
          );
        }
      });
  },
});

export const { reset, updateLocalProject } = projectSlice.actions;
export default projectSlice.reducer;

export const API_URL = process.env.REACT_APP_API_URL;

// Helper function to construct API endpoints
export const endpoints = {
  auth: {
    base: `${API_URL}/api/users`,
    login: `${API_URL}/api/users/login`,
  },
  projects: {
    base: `${API_URL}/api/projects`,
    documents: (projectId) => `${API_URL}/api/documents/${projectId}`,
  },
  workOrders: {
    base: `${API_URL}/api/workorders`,
  },
  templates: {
    base: `${API_URL}/api/templates`,
  },
  companies: {
    base: `${API_URL}/api/companies`,
    documents: `${API_URL}/api/company-documents`,
  },
  users: {
    base: `${API_URL}/api/users`,
    company: `${API_URL}/api/users/company`,
  },
  budgets: {
    base: `${API_URL}/api/budgets`,
    details: (budgetId) => `${API_URL}/api/budgets/${budgetId}`,
    update: (budgetId) => `${API_URL}/api/budgets/${budgetId}`,
    status: (budgetId) => `${API_URL}/api/budgets/${budgetId}/status`,
    history: (budgetId) => `${API_URL}/api/budgets/${budgetId}/history`,
  },
}; 
import axios from 'axios';
import { endpoints } from '../../config/api';

// Get token from localStorage
const getToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.token;
};

// Document upload and management
const uploadDocument = async (formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data'
    }
  };
  
  const response = await axios.post(
    endpoints.companies.documents,
    formData,
    config
  );
  return response.data;
};

const getDocuments = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  
  const response = await axios.get(
    `${endpoints.companies.documents}`,
    config
  );
  return response.data;
};

const deleteDocument = async (documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  
  const response = await axios.delete(
    `${endpoints.companies.documents}/${documentId}`,
    config
  );
  return response.data;
};

const getDocumentUrl = async (documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  
  const response = await axios.get(
    `${endpoints.companies.documents}/${documentId}/url`,
    config
  );
  return response.data;
};

// Company management
const getCompanyDetails = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  
  const response = await axios.get(
    `${endpoints.companies.base}/me`,
    config
  );
  return response.data;
};

const updateCompany = async (companyData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  
  const response = await axios.put(
    `${endpoints.companies.base}/me`,
    companyData,
    config
  );
  return response.data;
};

export const companyService = {
  uploadDocument,
  getDocuments,
  deleteDocument,
  getDocumentUrl,
  getCompanyDetails,
  updateCompany
}; 
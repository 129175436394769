import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../features/users/userSlice';
import Button from '../common/Button';
import { toast } from 'react-toastify';

const DeleteConfirmationModal = ({ user, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await dispatch(deleteUser(user._id)).unwrap();
      toast.success('User deleted successfully');
      onSuccess();
    } catch (error) {
      toast.error(error?.message || 'Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Delete Team Member</h2>
        <p className="text-sm text-gray-500 mb-4">
          Are you sure you want to delete {user.firstName} {user.lastName}? This action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <Button
            variant="secondary"
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal; 
import React, { useState, useEffect } from 'react';
import { 
  DocumentIcon, 
  PhotoIcon, 
  DocumentTextIcon,
  TableCellsIcon,
  VideoCameraIcon,
  MusicalNoteIcon
} from '@heroicons/react/24/outline';
import projectService from '../../features/projects/projectService';
import { useSelector } from 'react-redux';

const DocumentThumbnail = ({ document, className = "h-10 w-10" }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth); // Get user from Redux state

  useEffect(() => {
    const generateThumbnail = async () => {
      if (document.contentType?.startsWith('image/')) {
        try {
          // Get signed URL for the image using the user's token
          const response = await projectService.getDocumentUrl(
            document.projectId, 
            document._id, 
            user.token // Use token from Redux state
          );
          if (response.url) {
            setThumbnail(response.url);
          }
        } catch (error) {
          console.error('Error loading image thumbnail:', error);
        }
      }
      setLoading(false);
    };

    if (document.projectId && document._id && user?.token) {
      generateThumbnail();
    }
  }, [document, user?.token]);

  if (loading) {
    return <div className={`${className} bg-gray-200 animate-pulse rounded`} />;
  }

  // If we have a thumbnail image
  if (thumbnail) {
    return (
      <div className={`${className} relative rounded overflow-hidden bg-gray-100`}>
        <img
          src={thumbnail}
          alt={document.name}
          className="object-cover w-full h-full"
          onError={(e) => {
            console.error('Image load error:', e);
            setThumbnail(null);
          }}
        />
      </div>
    );
  }

  // Return appropriate icon based on file type
  const getFileIcon = () => {
    const type = document.contentType;
    
    if (type?.startsWith('image/')) {
      return <PhotoIcon className={`${className} text-blue-600`} />;
    }
    if (type?.includes('pdf')) {
      return <DocumentTextIcon className={`${className} text-red-600`} />;
    }
    if (type?.includes('spreadsheet') || type?.includes('excel')) {
      return <TableCellsIcon className={`${className} text-green-600`} />;
    }
    if (type?.includes('video/')) {
      return <VideoCameraIcon className={`${className} text-purple-600`} />;
    }
    if (type?.includes('audio/')) {
      return <MusicalNoteIcon className={`${className} text-yellow-600`} />;
    }
    return <DocumentIcon className={`${className} text-gray-600`} />;
  };

  return (
    <div className="p-2 bg-gray-50 rounded">
      {getFileIcon()}
    </div>
  );
};

export default DocumentThumbnail; 
import { createContext, useContext, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const MapsContext = createContext(null);

// Define libraries array as a static constant
const LIBRARIES = ["places"];

export const MapsProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={LIBRARIES}
      onLoad={() => setIsLoaded(true)}
    >
      <MapsContext.Provider value={{ isLoaded }}>
        {children}
      </MapsContext.Provider>
    </LoadScript>
  );
};

export const useMaps = () => {
  const context = useContext(MapsContext);
  if (!context) {
    throw new Error('useMaps must be used within a MapsProvider');
  }
  return context;
}; 
import { useState } from 'react';
import Button from '../common/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import GoogleLocationPicker from '../location/GoogleLocationPicker';
import { useSelector } from 'react-redux';

const PROJECT_STATUSES = [
  { value: 'pending', label: 'Pending' },
  { value: 'sold', label: 'Sold' },
  { value: 'pre-construction', label: 'Pre-Construction' },
  { value: 'permitting', label: 'Permitting' },
  { value: 'construction', label: 'Construction' },
  { value: 'done', label: 'Done' }
];

const EditProjectModal = ({ project, onClose, onUpdate, isSubmitting }) => {
  const { user: { user } } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    name: project.name || '',
    description: project.description || '',
    location: project.location || {},
    status: project.status || 'pending'
  });
  const [errors, setErrors] = useState({});

  const canUpdateStatus = user?.role?.toUpperCase() === 'ADMIN' || 
                         user?.role?.toUpperCase() === 'PROJECT_MANAGER';

  console.log('User data:', user);
  console.log('User role:', user?.role);
  console.log('Can update status:', canUpdateStatus);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = 'Project name is required';
    }
    if (!formData.location.address) {
      validationErrors.location = 'Location is required';
    }
    if (!formData.description.trim()) {
      validationErrors.description = 'Description is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    onUpdate(formData);
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    // Clear error when field is updated
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: undefined
      }));
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                onClick={onClose}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  Edit Project Details
                </h3>
                <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Project Name
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                    {errors.name && (
                      <p className="mt-1 text-xs text-red-600">{errors.name}</p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      value={formData.description}
                      onChange={(e) => handleChange('description', e.target.value)}
                      rows={3}
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                    {errors.description && (
                      <p className="mt-1 text-xs text-red-600">{errors.description}</p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <GoogleLocationPicker
                      value={formData.location.address}
                      onChange={(locationData) => handleChange('location', locationData.location)}
                      className="mt-1 block w-full"
                    />
                    {errors.location && (
                      <p className="mt-1 text-xs text-red-600">{errors.location}</p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Project Status
                      {!canUpdateStatus && (
                        <span className="ml-2 text-xs text-gray-500">
                          (Only admins and project managers can change status)
                        </span>
                      )}
                    </label>
                    <select
                      value={formData.status}
                      onChange={(e) => handleChange('status', e.target.value)}
                      disabled={!canUpdateStatus}
                      className={`mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm 
                        ${canUpdateStatus 
                          ? 'focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500' 
                          : 'bg-gray-50 cursor-not-allowed'}`}
                    >
                      {PROJECT_STATUSES.map(status => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-full sm:ml-3 sm:w-auto"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={onClose}
                      className="mt-3 w-full sm:mt-0 sm:w-auto"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectModal; 
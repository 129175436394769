import axios from 'axios';
import { endpoints } from '../../config/api';

// Create new work order
const createWorkOrder = async (workOrderData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(endpoints.workOrders.base, workOrderData, config);
  return response.data;
};

// Get all work orders
const getWorkOrders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(endpoints.workOrders.base, config);
  return response.data;
};

// Get single work order
const getWorkOrder = async (workOrderId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${endpoints.workOrders.base}/${workOrderId}`, config);
  return response.data;
};

// Update work order
const updateWorkOrder = async (workOrderId, workOrderData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${endpoints.workOrders.base}/${workOrderId}`, workOrderData, config);
  return response.data;
};

// Delete work order
const deleteWorkOrder = async (workOrderId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${endpoints.workOrders.base}/${workOrderId}`, config);
  return response.data;
};

// Assign work order to project
const assignToProject = async (workOrderId, projectId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${endpoints.workOrders.base}/${workOrderId}/assign-project`,
    { projectId },
    config
  );
  return response.data;
};

// Assign work order to user
const assignToUser = async (workOrderId, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${endpoints.workOrders.base}/${workOrderId}/assign-user`,
    { userId },
    config
  );
  return response.data;
};

// Upload document
const uploadDocument = async (workOrderId, formData, token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${endpoints.workOrders.base}/${workOrderId}/documents`,
    formData,
    config
  );
  return response.data;
};

// Delete document
const deleteDocument = async (workOrderId, documentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${endpoints.workOrders.base}/${workOrderId}/documents/${documentId}`,
    config
  );
  return response.data;
};

// Get document URL
const getDocumentUrl = async (workOrderId, documentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${endpoints.workOrders.base}/${workOrderId}/documents/${documentId}/url`,
    config
  );
  return response.data;
};

const workOrderService = {
  createWorkOrder,
  getWorkOrders,
  getWorkOrder,
  updateWorkOrder,
  deleteWorkOrder,
  assignToProject,
  assignToUser,
  uploadDocument,
  deleteDocument,
  getDocumentUrl,
};

export default workOrderService; 
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createWorkOrder } from '../features/workOrders/workOrderSlice';
import Card from '../components/common/Card';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { Autocomplete } from '@react-google-maps/api';
import Map from '../components/common/Map';
import { useMaps } from '../contexts/MapsContext';

/* global google */

const baseInputClass = "block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder-gray-400 text-sm shadow-sm transition-colors focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none";

const MapLoadingSpinner = () => (
  <div className="h-[300px] flex items-center justify-center bg-gray-50 rounded-lg">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

const getInputClassName = (error) => {
  return `${baseInputClass} ${error ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : ''}`.trim();
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

// Define libraries array as a static constant
const LIBRARIES = ["places"];

const CreateWorkOrderComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoaded } = useMaps();
  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });
  const [mapLoaded, setMapLoaded] = useState(false);
  
  const [formData, setFormData] = useState({
    client: {
      name: '',
      email: '',
      phone: '',
    },
    address: {
      formattedAddress: '',
      lat: null,
      lng: null,
      placeId: '',
    },
    underWarranty: false,
    color: 'White',
    screenType: 'Regular 14x18',
    inspectionRelated: false,
    areaOfConcern: {
      gutter: false,
      screen: false,
      aluminum: false,
      panels: false,
      concrete: false,
      door: false,
      screws: false,
      other: '',
    },
    picturesProvided: false,
    additionalNotes: '',
    workPerformed: '',
    amountDue: 0,
    cashOnDelivery: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name === 'client.phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prev => ({
        ...prev,
        client: {
          ...prev.client,
          phone: formattedPhone
        }
      }));
      return;
    }
    
    if (name.startsWith('client.')) {
      const clientField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        client: {
          ...prev.client,
          [clientField]: value
        }
      }));
    } else if (name.startsWith('areaOfConcern.')) {
      const concernField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        areaOfConcern: {
          ...prev.areaOfConcern,
          [concernField]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = {
          formattedAddress: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeId: place.place_id,
        };
        
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        });
        
        setFormData(prev => ({
          ...prev,
          address: location
        }));

        if (map) {
          map.panTo({ lat: location.lat, lng: location.lng });
          map.setZoom(15);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createWorkOrder(formData)).unwrap();
      navigate('/work-orders');
    } catch (error) {
      console.error('Failed to create work order:', error);
    }
  };

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    setMapLoaded(true);
  }, []);

  const renderClientInformation = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
        <input
          type="text"
          name="client.name"
          value={formData.client.name}
          onChange={handleChange}
          required
          className={getInputClassName()}
          placeholder="Enter client name"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
        <input
          type="email"
          name="client.email"
          value={formData.client.email}
          onChange={handleChange}
          required
          className={getInputClassName()}
          placeholder="Enter client email"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
        <input
          type="tel"
          name="client.phone"
          value={formData.client.phone}
          onChange={handleChange}
          required
          className={getInputClassName()}
          placeholder="(555) 555-5555"
          maxLength="14"
        />
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">Address</label>
        <div className="space-y-4">
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Search for address..."
              className={getInputClassName()}
              required
            />
          </Autocomplete>
          
          <div className="h-[300px] rounded-lg overflow-hidden">
            {!isLoaded ? <MapLoadingSpinner /> : (
              <div style={{ height: '100%', width: '100%' }}>
                <Map
                  center={mapCenter}
                  onLoad={handleMapLoad}
                  marker={formData.address.lat ? {
                    position: {
                      lat: parseFloat(formData.address.lat),
                      lng: parseFloat(formData.address.lng)
                    },
                    title: formData.address.formattedAddress
                  } : null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-6 py-8">
      <div className="max-w-[1440px] mx-auto">
        <Breadcrumbs 
          items={[
            { label: 'Work Orders', link: '/work-orders' },
            { label: 'Create Work Order' }
          ]} 
        />

        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Create Work Order</h1>
          <button
            onClick={() => navigate('/work-orders')}
            className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card>
              <h2 className="text-lg font-medium mb-4">Client Information</h2>
              {renderClientInformation()}
            </Card>

            <Card>
              <h2 className="text-lg font-medium mb-4">Service Details</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Color</label>
                  <select
                    name="color"
                    value={formData.color}
                    onChange={handleChange}
                    required
                    className={`${baseInputClass} bg-white`}
                  >
                    <option value="White">White</option>
                    <option value="Bronze">Bronze</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Screen Type</label>
                  <select
                    name="screenType"
                    value={formData.screenType}
                    onChange={handleChange}
                    required
                    className={`${baseInputClass} bg-white`}
                  >
                    <option value="Regular 14x18">Regular 14x18</option>
                    <option value="No-See-Ums 20/20">No-See-Ums 20/20</option>
                    <option value="17x14 Super Screen">17x14 Super Screen</option>
                    <option value="17x20 Super Screen">17x20 Super Screen</option>
                  </select>
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="underWarranty"
                      checked={formData.underWarranty}
                      onChange={handleChange}
                      className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">Under Warranty</span>
                  </label>
                </div>
              </div>
            </Card>

            <Card>
              <h2 className="text-lg font-medium mb-4">Areas of Concern</h2>
              <div className="space-y-4">
                {['gutter', 'screen', 'aluminum', 'panels', 'concrete', 'door', 'screws'].map((area) => (
                  <div key={area}>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name={`areaOfConcern.${area}`}
                        checked={formData.areaOfConcern[area]}
                        onChange={handleChange}
                        className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        {area.charAt(0).toUpperCase() + area.slice(1)}
                      </span>
                    </label>
                  </div>
                ))}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Other Concerns</label>
                  <textarea
                    name="areaOfConcern.other"
                    value={formData.areaOfConcern.other}
                    onChange={handleChange}
                    rows={3}
                    className={getInputClassName()}
                    placeholder="Describe other concerns..."
                  />
                </div>
              </div>
            </Card>

            <Card>
              <h2 className="text-lg font-medium mb-4">Additional Information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Work Performed</label>
                  <textarea
                    name="workPerformed"
                    value={formData.workPerformed}
                    onChange={handleChange}
                    required
                    rows={3}
                    className={getInputClassName()}
                    placeholder="Describe the work performed..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Amount Due</label>
                  <input
                    type="number"
                    name="amountDue"
                    value={formData.amountDue}
                    onChange={handleChange}
                    required
                    min="0"
                    step="0.01"
                    className={getInputClassName()}
                    placeholder="Enter the amount due"
                  />
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="cashOnDelivery"
                      checked={formData.cashOnDelivery}
                      onChange={handleChange}
                      className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">Cash on Delivery</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="picturesProvided"
                      checked={formData.picturesProvided}
                      onChange={handleChange}
                      className="w-4 h-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">Pictures Provided</span>
                  </label>
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="bg-emerald-500 hover:bg-emerald-600 text-white px-4 py-2 rounded-lg shadow-sm transition-colors"
            >
              Create Work Order
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateWorkOrderComponent; 
import { useCallback, useEffect, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const Map = ({ center, onLoad, markers = [], marker, zoom = 15, onClick, onMarkerClick, fitMarkers = false }) => {
  const [map, setMap] = useState(null);

  const handleLoad = useCallback((map) => {
    setMap(map);
    if (onLoad) {
      onLoad(map);
    }
  }, [onLoad]);

  const handleMarkerClick = (projectId) => {
    if (onMarkerClick) {
      onMarkerClick(projectId);
    }
  };

  // Fit bounds when markers or map changes, only if fitMarkers is true
  useEffect(() => {
    if (map && markers.length > 0 && fitMarkers) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(marker => {
        bounds.extend(marker.position);
      });
      map.fitBounds(bounds);

      // Optional: Add some padding
      const padding = { top: 50, right: 50, bottom: 50, left: 50 };
      map.fitBounds(bounds, padding);
    }
  }, [map, markers, fitMarkers]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
        fontFamily: 'inherit'
      }}
      center={center}
      zoom={zoom}
      onLoad={handleLoad}
      onClick={onClick}
      options={{
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
      }}
    >
      {/* Single marker case */}
      {marker && (
        <MarkerF
          position={marker.position}
          title={marker.title}
          // Make the main marker more prominent
          options={{
            zIndex: 1000, // Ensure main marker is on top
            animation: window.google.maps.Animation.DROP
          }}
        />
      )}
      
      {/* Multiple markers case */}
      {markers.map((marker, index) => (
        <MarkerF
          key={`${marker.position.lat}-${marker.position.lng}-${index}`}
          position={marker.position}
          title={marker.title}
          onClick={() => handleMarkerClick(marker.projectId)}
          options={{
            opacity: 0.7 // Make additional markers slightly transparent
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default Map; 
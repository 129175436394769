import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline';

const Breadcrumbs = ({ items }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-2 text-sm text-gray-500">
      <button 
        onClick={() => navigate('/')}
        className="hover:text-gray-700 flex items-center"
      >
        <HomeIcon className="w-4 h-4" />
      </button>
      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <span>/</span>
          {item.link ? (
            <button
              onClick={() => navigate(item.link)}
              className="hover:text-gray-700 font-semibold"
            >
              {item.label}
            </button>
          ) : (
            <span className="text-gray-700 font-semibold">{item.label}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs; 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import templateService from './templateService';

export const getTemplates = createAsyncThunk(
  'templates/getAll',
  async (token, thunkAPI) => {
    try {
      return await templateService.getTemplates(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  'templates/update',
  async (templateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      if (!templateData._id) {
        throw new Error('Template ID is missing');
      }
      return await templateService.updateTemplate(templateData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTemplate = createAsyncThunk(
  'templates/create',
  async ({ token, ...templateData }, thunkAPI) => {
    try {
      return await templateService.createTemplate(templateData, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const templateSlice = createSlice({
  name: 'templates',
  initialState: {
    templates: [],
    isLoading: false,
    isError: false,
    message: ''
  },
  reducers: {
    reset: (state) => {
      state.templates = []
      state.isLoading = false
      state.isError = false
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.templates = action.payload
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.templates = state.templates.map(template => 
          template._id === action.payload._id ? action.payload : template
        )
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.templates.push(action.payload)
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      });
  }
});

export default templateSlice.reducer; 
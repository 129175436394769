import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, DocumentIcon } from '@heroicons/react/24/outline';
import Card from '../components/common/Card';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { getWorkOrders, updateWorkOrder, assignToProject, assignToUser } from '../features/workOrders/workOrderSlice';
import { getCompanyUsers } from '../features/users/userSlice';
import jsPDF from 'jspdf';

const Spinner = () => (
  <div className="flex justify-center items-center h-96">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>
);

const WorkOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workOrders = [], isLoading } = useSelector((state) => state.workOrders);
  const { projects = [] } = useSelector((state) => state.projects);
  const { users = [] } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'dateRequested', direction: 'desc' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getWorkOrders());
    dispatch(getCompanyUsers());
  }, [dispatch]);

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  const sortedWorkOrders = [...(workOrders || [])].sort((a, b) => {
    if (!a || !b) return 0;
    
    if (sortConfig.key === 'client') {
      return sortConfig.direction === 'asc'
        ? (a.client?.name || '').localeCompare(b.client?.name || '')
        : (b.client?.name || '').localeCompare(a.client?.name || '');
    }
    
    if (sortConfig.key === 'dateRequested' || sortConfig.key === 'dateCompleted') {
      const dateA = new Date(a[sortConfig.key] || '');
      const dateB = new Date(b[sortConfig.key] || '');
      return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
    }

    if (sortConfig.key === 'amountDue') {
      return sortConfig.direction === 'asc'
        ? (a.amountDue || 0) - (b.amountDue || 0)
        : (b.amountDue || 0) - (a.amountDue || 0);
    }

    return sortConfig.direction === 'asc'
      ? String(a[sortConfig.key] || '').localeCompare(String(b[sortConfig.key] || ''))
      : String(b[sortConfig.key] || '').localeCompare(String(a[sortConfig.key] || ''));
  });

  const filteredWorkOrders = sortedWorkOrders.filter(workOrder => {
    if (!workOrder) return false;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      (workOrder.client?.name || '').toLowerCase().includes(searchLower) ||
      (workOrder.client?.email || '').toLowerCase().includes(searchLower) ||
      (workOrder.address?.formattedAddress || '').toLowerCase().includes(searchLower) ||
      (workOrder.technician || '').toLowerCase().includes(searchLower) ||
      (workOrder.status || '').toLowerCase().includes(searchLower) ||
      (workOrder.assignedToProject?.name || '').toLowerCase().includes(searchLower) ||
      (workOrder.createdBy ? 
        `${workOrder.createdBy.firstName} ${workOrder.createdBy.lastName}`.toLowerCase().includes(searchLower) 
        : ''
      ) ||
      (workOrder.assignedTo ? 
        `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}`.toLowerCase().includes(searchLower) 
        : ''
      )
    );
  });

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'bg-green-50 text-green-800';
      case 'in-progress':
        return 'bg-blue-50 text-blue-800';
      case 'cancelled':
        return 'bg-red-50 text-red-800';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? 
        <ChevronUpIcon className="w-4 h-4 inline-block ml-1" /> :
        <ChevronDownIcon className="w-4 h-4 inline-block ml-1" />;
    }
    return <ChevronUpIcon className="w-4 h-4 inline-block ml-1 text-gray-300" />;
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWorkOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredWorkOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAssignToProject = async (workOrderId, projectId) => {
    await dispatch(assignToProject({ workOrderId, projectId }));
    dispatch(getWorkOrders());
  };

  const handleAssignToUser = async (workOrderId, userId) => {
    await dispatch(assignToUser({ workOrderId, userId }));
    dispatch(getWorkOrders());
  };

  const handleCreateWorkOrder = () => {
    navigate('/work-orders/new');
  };

  const handleViewWorkOrder = (workOrderId) => {
    navigate(`/work-orders/${workOrderId}`);
  };

  const generatePDF = (workOrder) => {
    console.log('Generating PDF with data:', {
      workOrderCompany: workOrder?.company,
      userDetails: user?.user
    });

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });
    
    // Get company name and user details
    const companyName = workOrder?.company?.name || '';
    const userEmail = user?.user?.email || '';
    const userName = `${user?.user?.firstName || ''} ${user?.user?.lastName || ''}`;
    
    // Set fonts
    pdf.setFont('helvetica', 'bold');
    
    // Header
    pdf.setFontSize(20);
    pdf.text(companyName, pdf.internal.pageSize.width / 2, 20, { align: 'center' });
    pdf.setFontSize(16);
    pdf.text('Repair Order Request', pdf.internal.pageSize.width / 2, 30, { align: 'center' });

    // Reset font
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(12);

    // Date
    pdf.text(`Date Requested: ${new Date(workOrder.dateRequested).toLocaleDateString()}`, 20, 45);

    // Client Information Section
    pdf.setFont('helvetica', 'bold');
    pdf.text('Client Information:', 20, 60);
    pdf.setFont('helvetica', 'normal');
    pdf.text([
      `Name: ${workOrder.client?.name || ''}`,
      `Email: ${workOrder.client?.email || ''}`,
      `Phone: ${workOrder.client?.phone || ''}`,
      `Address: ${workOrder.address || ''}`
    ], 25, 70);

    // Service Details Section
    pdf.setFont('helvetica', 'bold');
    pdf.text('Service Details:', 20, 100);
    pdf.setFont('helvetica', 'normal');
    pdf.text([
      `Color: ${workOrder.color || ''}`,
      `Screen Type: ${workOrder.screenType || ''}`,
      `Under Warranty: ${workOrder.underWarranty ? 'Yes' : 'No'}`
    ], 25, 110);

    // Areas of Concern Section
    pdf.setFont('helvetica', 'bold');
    pdf.text('Areas of Concern:', 20, 135);
    pdf.setFont('helvetica', 'normal');
    
    const concerns = Object.entries(workOrder.areaOfConcern || {})
      .filter(([key, value]) => value && key !== 'other')
      .map(([key]) => `• ${key.charAt(0).toUpperCase() + key.slice(1)}`);

    if (concerns.length > 0) {
      pdf.text(concerns, 25, 145);
    }

    if (workOrder.areaOfConcern?.other) {
      pdf.text(`Other: ${workOrder.areaOfConcern.other}`, 25, 145 + (concerns.length * 7));
    }

    // Work Details Section
    const workDetailsY = 145 + (concerns.length * 7) + 20;
    pdf.setFont('helvetica', 'bold');
    pdf.text('Work Details:', 20, workDetailsY);
    pdf.setFont('helvetica', 'normal');
    
    // Add work performed with word wrap
    const workPerformedLines = pdf.splitTextToSize(
      `Work Performed: ${workOrder.workPerformed || ''}`, 
      170
    );
    pdf.text(workPerformedLines, 25, workDetailsY + 10);

    const workDetailsBottom = workDetailsY + 10 + (workPerformedLines.length * 7);
    pdf.text([
      `Amount Due: $${workOrder.amountDue?.toLocaleString() || '0'}`,
      `Technician: ${workOrder.technician || ''}`
    ], 25, workDetailsBottom);

    // Footer with user information
    pdf.setFontSize(10);
    pdf.text([
      companyName,
      `Generated by: ${userName}`,
      `Email: ${userEmail}`
    ], pdf.internal.pageSize.width / 2, 280, { 
      align: 'center',
      maxWidth: 170
    });

    // Add borders to the page
    pdf.rect(10, 10, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 20);

    // Save the PDF
    pdf.save(`work-order-${workOrder._id}.pdf`);
  };

  const renderTableHeader = () => (
    <thead className="bg-gray-50">
      <tr>
        <th 
          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
          onClick={() => handleSort('client')}
        >
          <div className="flex items-center">
            Client
            {getSortIcon('client')}
          </div>
        </th>
        <th 
          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
          onClick={() => handleSort('dateRequested')}
        >
          <div className="flex items-center">
            Date Requested
            {getSortIcon('dateRequested')}
          </div>
        </th>
        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Address
        </th>
        <th 
          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
          onClick={() => handleSort('status')}
        >
          <div className="flex items-center">
            Status
            {getSortIcon('status')}
          </div>
        </th>
        <th 
          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
          onClick={() => handleSort('amountDue')}
        >
          <div className="flex items-center">
            Amount Due
            {getSortIcon('amountDue')}
          </div>
        </th>
        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Created By
        </th>
        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Assigned To
        </th>
        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Project
        </th>
        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Actions
        </th>
      </tr>
    </thead>
  );

  const renderTableRow = (workOrder) => (
    <tr 
      key={workOrder._id}
      className="hover:bg-gray-50 cursor-pointer"
      onClick={() => handleViewWorkOrder(workOrder._id)}
    >
      <td className="px-4 py-4">
        <div className="text-sm font-medium text-gray-900">{workOrder.client?.name}</div>
        <div className="text-sm text-gray-500">{workOrder.client?.email}</div>
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">
        {workOrder.dateRequested ? new Date(workOrder.dateRequested).toLocaleDateString() : 'N/A'}
      </td>
      <td className="px-4 py-4">
        <div className="text-sm text-gray-900 truncate max-w-[200px]">
          {workOrder.address?.formattedAddress || 'N/A'}
        </div>
      </td>
      <td className="px-4 py-4">
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium ${getStatusColor(workOrder.status || 'pending')}`}>
          {workOrder.status || 'pending'}
        </span>
      </td>
      <td className="px-4 py-4 text-sm text-gray-900">
        ${(workOrder.amountDue || 0).toLocaleString()}
      </td>
      <td className="px-4 py-4">
        <div className="text-sm text-gray-900">
          {workOrder.createdBy ? 
            `${workOrder.createdBy.firstName} ${workOrder.createdBy.lastName}` : 
            'N/A'
          }
        </div>
      </td>
      <td className="px-4 py-4">
        <div className="text-sm text-gray-900">
          {workOrder.assignedTo ? 
            `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}` : 
            'Unassigned'
          }
        </div>
      </td>
      <td className="px-4 py-4">
        <div className="text-sm text-gray-900">
          {workOrder.assignedToProject ? 
            workOrder.assignedToProject.name : 
            'No Project'
          }
        </div>
      </td>
      <td className="px-4 py-4">
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click
            generatePDF(workOrder);
          }}
          className="text-gray-600 hover:text-blue-600 transition-colors"
          title="Download PDF"
        >
          <DocumentIcon className="w-5 h-5" />
        </button>
      </td>
    </tr>
  );

  const renderMobileView = () => (
    <div className="sm:hidden space-y-4">
      {currentItems.map((workOrder) => workOrder && (
        <div
          key={workOrder._id}
          className="bg-white rounded-lg shadow p-4 cursor-pointer hover:bg-gray-50"
          onClick={() => handleViewWorkOrder(workOrder._id)}
        >
          <div className="flex justify-between items-start mb-3">
            <div className="flex-1">
              <h3 className="text-base font-medium text-gray-900">{workOrder.client?.name}</h3>
              <p className="text-sm text-gray-500">{workOrder.client?.email}</p>
            </div>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium ${getStatusColor(workOrder.status || 'pending')}`}>
              {workOrder.status || 'pending'}
            </span>
          </div>

          <div className="space-y-2 text-sm">
            <div className="flex justify-between items-start gap-2">
              <span className="text-gray-500 min-w-[80px]">Address:</span>
              <span className="text-gray-900 text-right">{workOrder.address?.formattedAddress || 'N/A'}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-500">Date:</span>
              <span className="text-gray-900">
                {workOrder.dateRequested ? new Date(workOrder.dateRequested).toLocaleDateString() : 'N/A'}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-500">Amount:</span>
              <span className="text-gray-900 font-medium">
                ${(workOrder.amountDue || 0).toLocaleString()}
              </span>
            </div>

            <div className="pt-2 border-t border-gray-100">
              <div className="flex justify-between items-center mb-1">
                <span className="text-gray-500">Created By:</span>
                <span className="text-gray-900">
                  {workOrder.createdBy ? 
                    `${workOrder.createdBy.firstName} ${workOrder.createdBy.lastName}` : 
                    'N/A'
                  }
                </span>
              </div>

              <div className="flex justify-between items-center mb-1">
                <span className="text-gray-500">Assigned To:</span>
                <span className="text-gray-900">
                  {workOrder.assignedTo ? 
                    `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}` : 
                    'Unassigned'
                  }
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-gray-500">Project:</span>
                <span className="text-gray-900">
                  {workOrder.assignedToProject ? 
                    workOrder.assignedToProject.name : 
                    'No Project'
                  }
                </span>
              </div>
            </div>

            <div className="pt-2 flex justify-end">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  generatePDF(workOrder);
                }}
                className="inline-flex items-center gap-1 text-gray-600 hover:text-blue-600 transition-colors"
              >
                <DocumentIcon className="w-4 h-4" />
                <span className="text-sm">PDF</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto px-4 sm:px-6 py-8">
      <div className="max-w-[1440px] mx-auto">
        <Breadcrumbs 
          items={[
            { label: 'Work Orders' }
          ]} 
        />
        
        <div className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">Work Orders</h1>
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <input
              type="text"
              placeholder="Search work orders..."
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button 
              onClick={handleCreateWorkOrder}
              className="w-full sm:w-auto flex items-center justify-center gap-1 bg-emerald-500 hover:bg-emerald-600 text-white px-4 py-2 rounded-lg shadow-sm transition-colors"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
              Create Work Order
            </button>
          </div>
        </div>

        <Card>
          {/* Desktop view */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              {renderTableHeader()}
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((workOrder) => workOrder && renderTableRow(workOrder))}
              </tbody>
            </table>
          </div>

          {/* Mobile view */}
          {renderMobileView()}

          {/* Pagination */}
          <div className="px-4 py-3 bg-white border-t border-gray-200">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
              <div className="w-full sm:w-auto text-center sm:text-left">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to{' '}
                  <span className="font-medium">
                    {Math.min(indexOfLastItem, filteredWorkOrders.length)}
                  </span>{' '}
                  of <span className="font-medium">{filteredWorkOrders.length}</span> results
                </p>
              </div>
              <div className="flex justify-center w-full sm:w-auto">
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                        ${currentPage === number
                          ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                        }`}
                    >
                      {number}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default WorkOrders; 
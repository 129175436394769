import axios from 'axios';
import { endpoints } from '../../config/api';

// Add cache duration
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Update cache structure to include timestamp
const projectCache = new Map();
const budgetCache = new Map();

// Get all projects
const getProjects = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(endpoints.projects.base, config);
  
  // Transform location data if needed
  const projects = response.data.map(project => ({
    ...project,
    location: {
      ...project.location,
      address: project.location?.address || 'N/A',
      city: project.location?.city || 'N/A',
      state: project.location?.state || 'N/A',
      country: project.location?.country || 'N/A'
    }
  }));

  return projects;
};

// Create new project
const createProject = async (projectData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(endpoints.projects.base, projectData, config);
  return response.data;
};

// Updated getProject method to include authentication
const getProject = async (projectId, token) => {
  // Check cache first
  const cachedData = projectCache.get(projectId);
  if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
    return cachedData.data;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${endpoints.projects.base}/${projectId}`, config);
  
  const project = {
    ...response.data,
    location: {
      ...response.data.location,
      address: response.data.location?.address || 'N/A',
      city: response.data.location?.city || 'N/A',
      state: response.data.location?.state || 'N/A',
      country: response.data.location?.country || 'N/A'
    }
  };

  // Cache the result with timestamp
  projectCache.set(projectId, {
    data: project,
    timestamp: Date.now()
  });
  
  return project;
};

// Updated updateProject method to include authentication
const updateProject = async (projectId, updates, token) => {
  const response = await axios.put(`${endpoints.projects.base}/${projectId}`, updates, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  
  // Clear cache after successful update
  clearCache();
  
  return response.data;
};

const getCompanyUsers = async (token) => {
  const response = await fetch(endpoints.users.company, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(data.message || 'Failed to fetch company users');
  }
  
  return data.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`.trim() || user.email
  }));
};

// Add this method to your projectService
const createBudget = async (budgetData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(budgetData)
  const response = await axios.post(endpoints.budgets.base, budgetData, config);
  return response.data;
};

const updateBudget = async (budgetId, budgetData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    endpoints.budgets.update(budgetId),
    budgetData,
    config
  );

  return response.data;
};

const updateBudgetStatus = async (budgetId, status, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    endpoints.budgets.status(budgetId),
    { status },
    config
  );

  return response.data;
};

const getBudgetHistory = async (budgetId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${endpoints.budgets.base}/${budgetId}/history`, config);
  return response.data;
};

// Add this new method
const getBudgetDetails = async (budgetId, token) => {
  // Check cache first
  const cachedData = budgetCache.get(budgetId);
  if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
    return cachedData.data;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(endpoints.budgets.details(budgetId), config);
  
  // Cache the result with timestamp
  budgetCache.set(budgetId, {
    data: response.data,
    timestamp: Date.now()
  });
  
  return response.data;
};

const uploadProjectDocument = async (projectId, file, description, token) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);

  const response = await axios.post(
    `${endpoints.projects.documents(projectId)}/upload`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return response.data;
};

const deleteProjectDocument = async (projectId, documentId, token) => {
  const response = await axios.delete(
    `${endpoints.projects.documents(projectId)}/documents/${documentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return response.data;
};

const getDocumentUrl = async (projectId, documentId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const response = await axios.get(
      `${endpoints.projects.documents(projectId)}/documents/${documentId}/url`,
      config
    );

    return response.data;
  } catch (error) {
    console.error('Error getting document URL:', error);
    throw error;
  }
};

// Update clearCache to be more specific
const clearCache = (type) => {
  if (!type) {
    projectCache.clear();
    budgetCache.clear();
  } else if (type === 'project') {
    projectCache.clear();
  } else if (type === 'budget') {
    budgetCache.clear();
  }
};

// Add this to your projectService
const assignUserToSubtask = async (projectId, data, token) => {
  console.log('Service - assignUserToSubtask called with:', { projectId, data });
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.patch(
      `${endpoints.projects.base}/${projectId}/subtask/assign`,
      data,
      config
    );
    console.log('Service - assignUserToSubtask response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Service - assignUserToSubtask error:', error.response?.data || error);
    throw error;
  }
};

const projectService = {
  getProjects,
  createProject,
  getProject,
  updateProject,
  getCompanyUsers,
  createBudget,
  updateBudget,
  updateBudgetStatus,
  getBudgetHistory,
  getBudgetDetails,
  uploadProjectDocument,
  deleteProjectDocument,
  getDocumentUrl,
  clearCache,
  assignUserToSubtask
};

export default projectService;
